import { DefaultFilter, FilterKind } from 'components/FiltersDrawer/filters.const'
import { ActiveFilters } from './analytics'
import { Comparator } from './utils'
export interface FilterInput {
  name: FilterKind
  value: string
}

export interface FilterFacet extends FilterInput {
  count?: number
  label: string
  analyticsTag: string
}

export type FilterOption = FilterFacet & {
  selected: boolean
  translatedLabel?: string
  description?: string
}

export type BrandFilterOption = FilterOption & {
  originalBrandCode: string
}

export type FilterState = DefaultFilter & {
  options: FilterOption[] | BrandFilterOption[]
  translatedLabel?: string
}

export type FiltersState = {
  [kind in FilterKind]: FilterState
}

export type AppFiltersKind = 'q' | 'sort'

export type FiltersFromUrl = {
  [kind in FilterKind | AppFiltersKind]?: string | string[]
}

export interface FilterConfig {
  showIcon?: boolean
  withDescription?: boolean
}

export enum ProductTypeValues {
  OPTICAL = '1',
  SUN = '2',
  NUANCE = 'ELECTRONICS AUDIO OPTICAL',
}

export enum FirstLevelMenuValues {
  EYEWEAR_ACCESSORIES = 'Eyewear Accessories',
  ACCESSORIES = 'Accessories',
  GOGGLES = 'Goggles',
  GOGGLES_ACCESSORIES = 'Goggles Accessories',
}

export enum GenderValues {
  MEN = '1',
  WOMEN = '2',
  KIDS = '3',
}

export type FilterOptionsComparator = Comparator<FilterOption>

export enum SizeTypeValues {
  XXS = 'XXS',
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export type FormattedFiltersType = string | string[] | number | number[] | boolean | undefined

export type FormattedFilters = Record<string, FormattedFiltersType>

export type FiltersFromUrlKind = FilterKind | AppFiltersKind

export type FiltersGroupConfig = Record<FilterKind, FilterConfig | null> | Record<string, unknown>

export type TogglableProductTypeValues = ProductTypeValues.OPTICAL | ProductTypeValues.SUN

export interface PreselectedFilters {
  filters: ActiveFilters[]
  productTypeCounts: Record<TogglableProductTypeValues, number>
}
