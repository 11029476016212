import {
  ADD_VISITED_EXCLUSIVE_LENSES_MODEL,
  CLEAR_FA_PRESELECTED_FILTERS,
  CLEAR_PLP_PRESELECTED_FILTERS,
  CLOSE_VISIBLE_DRAWER,
  HIDE_ALL_DRAWER,
  SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE,
  SET_FILTERS_COUNT,
  SET_HAS_CHANGED_PAGE,
  SET_VISIBILITY_WARNING_MODAL,
  SET_VISIBLE_DIGITAL_COUVETTE,
  SET_VISIBLE_DRAWER,
  SET_VISIBLE_MODAL,
  TOGGLE_BARCODE_SCANNER_DRAWER,
  TOGGLE_FILTERS_DRAWER,
  TOGGLE_LANGUAGE_SELECTOR,
  TOGGLE_MENU_DRAWER,
  TOGGLE_MINICART_DRAWER,
  TOGGLE_PDP_INFO_DRAWER,
  TOGGLE_PROFILE_RECAP_DRAWER,
  TOGGLE_SEARCH_DRAWER,
  TOGGLE_SIMILAR_STYLE,
  UIActionTypes,
  UPDATE_FA_PRESELECTED_FILTERS,
  UPDATE_PLP_PRESELECTED_FILTERS,
} from 'actions/ui'
import { PreselectedFilters } from 'types/filter'

export interface UIState {
  menuDrawerIsVisible: boolean
  filtersDrawerIsVisible: boolean
  minicartDrawerIsVisible: boolean
  searchDrawerIsVisible: boolean
  profileRecapDrawerIsVisible: boolean
  languageSelectorIsVisible: boolean
  pdpInfosIsVisible: boolean
  similarStyleIsVisible: boolean
  hasChangedPage: boolean
  isWarningModalvisible: boolean
  shouldUpdatePlpPreselectedFilters: boolean
  shouldUpdateFaPreselectedFilters: boolean
  plpPreselectedFilters?: PreselectedFilters
  faPreselectedFilters?: PreselectedFilters
  isCustomLogoHeroSlideActive: boolean
  barcodeScannerDrawerIsVisible: boolean
  exclusiveLensesVisitedModels: string[]
  filtersCount: number
  visibleModalKey: string
  visibleDrawerKey: string
  visibleDigitalCouvette: boolean
}

const initialState: UIState = {
  isWarningModalvisible: false,
  menuDrawerIsVisible: false,
  filtersDrawerIsVisible: false,
  minicartDrawerIsVisible: false,
  searchDrawerIsVisible: false,
  profileRecapDrawerIsVisible: false,
  languageSelectorIsVisible: false,
  pdpInfosIsVisible: false,
  similarStyleIsVisible: false,
  hasChangedPage: false,
  shouldUpdatePlpPreselectedFilters: false,
  shouldUpdateFaPreselectedFilters: false,
  isCustomLogoHeroSlideActive: false,
  barcodeScannerDrawerIsVisible: false,
  exclusiveLensesVisitedModels: [],
  filtersCount: 0,
  visibleModalKey: undefined,
  visibleDrawerKey: undefined,
  visibleDigitalCouvette: false,
}

export default (state = initialState, action: UIActionTypes): UIState => {
  switch (action.type) {
    case TOGGLE_MENU_DRAWER:
      return { ...state, menuDrawerIsVisible: !state.menuDrawerIsVisible }
    case TOGGLE_MINICART_DRAWER:
      return { ...state, minicartDrawerIsVisible: !state.minicartDrawerIsVisible }
    case TOGGLE_FILTERS_DRAWER:
      return { ...state, filtersDrawerIsVisible: !state.filtersDrawerIsVisible }
    case TOGGLE_SEARCH_DRAWER:
      return { ...state, searchDrawerIsVisible: !state.searchDrawerIsVisible }
    case TOGGLE_PROFILE_RECAP_DRAWER:
      return { ...state, profileRecapDrawerIsVisible: !state.profileRecapDrawerIsVisible }
    case TOGGLE_PDP_INFO_DRAWER:
      return { ...state, pdpInfosIsVisible: !state.pdpInfosIsVisible }
    case TOGGLE_LANGUAGE_SELECTOR:
      return { ...state, languageSelectorIsVisible: !state.languageSelectorIsVisible }
    case SET_FILTERS_COUNT:
      return { ...state, filtersCount: action.payload }
    case TOGGLE_SIMILAR_STYLE:
      return {
        ...state,
        similarStyleIsVisible: !state.similarStyleIsVisible,
      }
    case TOGGLE_BARCODE_SCANNER_DRAWER:
      return {
        ...state,
        barcodeScannerDrawerIsVisible: !state.barcodeScannerDrawerIsVisible,
      }
    case HIDE_ALL_DRAWER:
      return {
        ...initialState,
        filtersCount: state.filtersCount,
        hasChangedPage: state.hasChangedPage,
        isWarningModalvisible: state.isWarningModalvisible,
        shouldUpdatePlpPreselectedFilters: state.shouldUpdatePlpPreselectedFilters,
        isCustomLogoHeroSlideActive: state.isCustomLogoHeroSlideActive,
        exclusiveLensesVisitedModels: state.exclusiveLensesVisitedModels,
        plpPreselectedFilters: state.plpPreselectedFilters,
        faPreselectedFilters: state.faPreselectedFilters,
      }
    case SET_HAS_CHANGED_PAGE:
      return {
        ...state,
        hasChangedPage: true,
      }
    case SET_VISIBILITY_WARNING_MODAL: {
      return { ...state, isWarningModalvisible: action.payload.visible }
    }

    case UPDATE_PLP_PRESELECTED_FILTERS: {
      return {
        ...state,
        plpPreselectedFilters: action.payload.filters,
        shouldUpdatePlpPreselectedFilters: false,
      }
    }
    case UPDATE_FA_PRESELECTED_FILTERS: {
      return {
        ...state,
        faPreselectedFilters: action.payload.filters,
        shouldUpdateFaPreselectedFilters: false,
      }
    }
    case CLEAR_PLP_PRESELECTED_FILTERS: {
      return {
        ...state,
        shouldUpdatePlpPreselectedFilters: true,
        plpPreselectedFilters: undefined,
      }
    }
    case CLEAR_FA_PRESELECTED_FILTERS: {
      return {
        ...state,
        shouldUpdateFaPreselectedFilters: true,
        faPreselectedFilters: undefined,
      }
    }
    case SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE: {
      return {
        ...state,
        isCustomLogoHeroSlideActive: action.payload.isActive,
      }
    }
    case ADD_VISITED_EXCLUSIVE_LENSES_MODEL: {
      return {
        ...state,
        exclusiveLensesVisitedModels: [
          ...state.exclusiveLensesVisitedModels,
          action.payload.productModel,
        ],
      }
    }

    case SET_FILTERS_COUNT:
      return { ...state, filtersCount: action.payload }

    case SET_VISIBLE_MODAL:
      return { ...state, visibleModalKey: action.payload.modalKey }

    case SET_VISIBLE_DRAWER:
      return { ...state, visibleDrawerKey: action.payload.drawerKey }

    case SET_VISIBLE_DIGITAL_COUVETTE:
      return { ...state, visibleDigitalCouvette: !state.visibleDigitalCouvette }

    case CLOSE_VISIBLE_DRAWER:
      return {
        ...state,
        visibleDrawerKey:
          action.payload.drawerKey === state.visibleDrawerKey ? undefined : state.visibleDrawerKey,
      }

    default:
      return state
  }
}
