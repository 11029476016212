import 'antd/dist/antd.less'
import { ConfigOverride } from 'components/EnvironmentInfo/ConfigOverride'
import { Service } from 'components/EnvironmentInfo/Service'
import { BiometricDataConsent } from 'components/FrameAdvisor/BiometricDataConsent'
import { FrameAdvisorRegulations } from 'components/FrameAdvisor/Regulations'
import { VKeyboardProvider } from 'components/Keyboard'
import Loading from 'components/Loading'
import config from 'config'
import { ConnectedRouter } from 'connected-react-router'
import { AnalyticsProvider, useAnalyticsNewSession } from 'containers/AnalyticsProvider'
import { AppRouteActions } from 'containers/AppRouteActions'
import AuthChecker from 'containers/AuthChecker'
import { BarcodeActions } from 'containers/BarcodeScanActions'
import { COURTESY_ROUTE, CourtesyChecker } from 'containers/CourtesyChecker'
import CourtesyPage from 'containers/CourtesyPage'
import LoginBypass from 'containers/LoginBypass'
import { useRBCustomModels } from 'data'
import { useConfigOverrideExpiration } from 'hooks/useConfigOverrideExpiration'
import { useDisablePinchZoom } from 'hooks/useGestureHandler'
import { useResetSS } from 'hooks/useResetSS'
import { useStoreContext } from 'hooks/useStoreContext'
import { setReturnUrl } from 'libs/custom'
import { ResetProvider } from 'providers/resetProvider'
import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch, matchPath } from 'react-router-dom'
import { OfflineWarning } from './components/OfflineWarning'
import { PrivateRoute } from './components/PrivateRoute'
import { localStorageKeyPrefix } from './config/config.base'
import LoginRedirect from './containers/LoginRedirect'
import { useApplicationOnlineStatus } from './hooks/useOnlineStatus'
import { isAppReadOnly } from './libs/auth'
import { emitSignal, useInterval } from './libs/signal'
import { getKeyboardLayout, isScreenSaverRunning, useVKeyboard } from './libs/utils'
import { NavigationProvider } from './providers/navigationProvider'
import store, { history } from './store'

interface RoutesProps extends RouteComponentProps {
  rbCustomEnabled: boolean
  ooCustomEnabled: boolean
}

type CustomDetailsProps = RouteComponentProps<{ modelCode: string; recipeId?: string }>

// CONTAINERS
const Cart = React.lazy(() => import('./containers/Cart'))
const Home = React.lazy(() => import('./containers/Home'))
const ProductsList = React.lazy(() => import('./containers/ProductsList'))
const ProductDetails = React.lazy(() => import('./containers/ProductDetails'))
const Wishlist = React.lazy(() => import('./containers/Wishlist'))
const OoCustomDetails = React.lazy(() => import('./containers/OoCustomDetails'))
const RbCustomDetails = React.lazy(() => import('./containers/RbCustomDetails'))
const Brands = React.lazy(() => import('./containers/Brands'))
const OrderCheck = React.lazy(() => import('./containers/OrderCheck'))
const CheckoutV3 = React.lazy(() => import('./containers/CheckoutV3'))
const ThankYou = React.lazy(() => import('./containers/ThankYou'))
const ThankYouMyEssilor = React.lazy(() => import('./containers/ThankYouMyEssilor'))
const SupernovaHome = React.lazy(() => import('./containers/SupernovaHome'))
const NuanceHome = React.lazy(() => import('./containers/NuanceHome'))
// Frame Advisor Containers
const Scanning = React.lazy(() => import('./containers/FrameAdvisor/fsa/scanning'))
const Connected = React.lazy(() => import('./containers/FrameAdvisor/fsa/connected'))

const FrameAdvisorPlaylist = React.lazy(() => import('./containers/FrameAdvisor/playlist'))
const Survey = React.lazy(() => import('./containers/FrameAdvisor/survey'))
const OnBoarding = React.lazy(() => import('./containers/FrameAdvisor/onboarding'))

const UserProfile = React.lazy(() => import('./containers/FrameAdvisor/profile'))
const Logout = React.lazy(() => import('./containers/FrameAdvisor/logout'))
const FrameAdvisorHandoff = React.lazy(() => import('./containers/FrameAdvisor/handoff'))
const FrameAdvisorSuggestions = React.lazy(() => import('./containers/FrameAdvisor/suggestions'))
const SessionHandoffRetriever = React.lazy(() => import('./containers/SessionHandoffRetriever'))

const Gens = React.lazy(() => import('./containers/GenS'))

const Routes: React.FC<RoutesProps> = ({ match, rbCustomEnabled, ooCustomEnabled }) => {
  const deviceSignalTimeout = config.deviceSignalTimeout
  useInterval(() => {
    emitSignal()
  }, deviceSignalTimeout)

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Home} />
      <Route exact path={`${match.url}/products`} component={ProductsList} />
      <Route exact path={`${match.url}/products/:model/:moco/:upc`} component={ProductDetails} />
      <Route exact path={`${match.url}/brands`} component={Brands} />
      <Route exact path={`${match.url}/wishlist`} component={Wishlist} />
      <Route
        exact
        path={`${match.url}/custom/oo`}
        render={(props: CustomDetailsProps) =>
          ooCustomEnabled ? (
            <OoCustomDetails key={props.match.params.modelCode} {...props} />
          ) : (
            <Redirect to={match.url} />
          )
        }
      />
      <Route
        exact
        path={`${match.url}/custom/oo/pdp/:modelCode/:recipeId?`}
        render={(props: CustomDetailsProps) =>
          ooCustomEnabled ? (
            <OoCustomDetails key={props.match.params.modelCode} {...props} />
          ) : (
            <Redirect to={match.url} />
          )
        }
      />
      <Route
        exact
        path={`${match.url}/custom/rb/:category?`}
        render={(props: CustomDetailsProps) =>
          rbCustomEnabled ? <RbCustomDetails {...props} /> : <Redirect to={match.url} />
        }
      />
      <Route
        exact
        path={`${match.url}/custom/rb/:category?/pdp/:modelCode/:recipeId?`}
        render={(props: CustomDetailsProps) =>
          rbCustomEnabled ? <RbCustomDetails {...props} /> : <Redirect to={match.url} />
        }
      />
      <Route exact path={`${match.url}/cart`} component={Cart} />
      <Route exact path={`${match.url}/checkout-v3/order-check`} component={OrderCheck} />
      <Route exact path={`${match.url}/checkout-v3`} component={CheckoutV3} />
      <Route
        exact
        path={`${match.url}/checkout-v3/myessilor/thank-you`}
        component={ThankYouMyEssilor}
      />
      <Route exact path={`${match.url}/checkout-v3/thank-you`} component={ThankYou} />
      <Route exact path={`${match.url}/scan-barcode`} component={BarcodeActions} />
      <Route exact path={`${match.url}/supernova-home/:defaultModel?`} component={SupernovaHome} />

      <Route exact path={`${match.url}/frame-advisor/playlist`} component={FrameAdvisorPlaylist} />
      <Route
        exact
        path={`${match.url}/frame-advisor/playlist/:playlistId`}
        component={FrameAdvisorSuggestions}
      />
      <Route exact path={`${match.url}/frame-advisor/onboarding`} component={OnBoarding} />
      <Route
        exact
        path={`${match.url}/frame-advisor/onboarding/biometric-data-consent`}
        component={BiometricDataConsent}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/onboarding/privacy-policy`}
        component={FrameAdvisorRegulations}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/onboarding/bipa-policy`}
        component={FrameAdvisorRegulations}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/onboarding/terms-and-conditions`}
        component={FrameAdvisorRegulations}
      />
      <Route exact path={`${match.url}/frame-advisor/survey/:stepId?`} component={Survey} />

      {/* All steps for the scanning */}
      <Route exact path={`${match.url}/frame-advisor/fsa/scanning`} component={Scanning} />
      <Route
        exact
        path={`${match.url}/frame-advisor/fsa/connected/:userId`}
        component={Connected}
      />
      {/* User profile page -> can also be used to skip if you provide a videoId directly in the URL*/}
      <Route exact path={`${match.url}/frame-advisor/profile/:userId`} component={UserProfile} />
      <Route exact path={`${match.url}/frame-advisor/logout`} component={Logout} />
      {/* GenS*/}
      <Route exact path={`${match.url}/gen-s/:model/:moco/:upc`} component={Gens} />
      <Route exact path={`${match.url}/nuance-home`} component={NuanceHome} />
    </Switch>
  )
}

const Base: React.FC<RouteComponentProps> = props => {
  const store = useStoreContext()
  const { isOffline } = useApplicationOnlineStatus()

  useAnalyticsNewSession(store)

  if (isOffline) return <OfflineWarning />

  const { remixEnabled, ocpEnabled } = store

  return (
    <Suspense fallback={<Loading />}>
      <Routes {...props} rbCustomEnabled={remixEnabled} ooCustomEnabled={ocpEnabled} />
    </Suspense>
  )
}

const BaseWrapper: React.FC<RouteComponentProps> = props => {
  const resetSS = useResetSS()

  useRBCustomModels({
    pollInterval: 1000 * 60 * 60,
  })

  return (
    <AnalyticsProvider analyticsConfig={config.tealiumAnalytics}>
      <ResetProvider
        onReset={resetSS}
        timeoutMs={config.resetAppTimeOut}
        resetKeyPrefix={localStorageKeyPrefix}
      >
        <Base {...props} />
      </ResetProvider>
    </AnalyticsProvider>
  )
}
const keyboardYCord = -(window.innerHeight / 2 - 150) //150 is half the height of the Virtual Keyboard

const App: React.FC = () => {
  const { t } = useTranslation()
  const { analyticsUrl, analyticsEnabled } = config
  const [forceKeybordHide, setForceKeybordHide] = useState(false)
  useDisablePinchZoom(true)
  useConfigOverrideExpiration()

  useEffect(() => {
    const setReturnUrlForCustom = location => {
      if (
        !matchPath(location.pathname, {
          path: '/:store_type/:store_id/:lang/custom/',
        })
      ) {
        setReturnUrl(`${location.pathname}${location.search}`)
      }
    }

    history.listen(location => {
      setForceKeybordHide(isScreenSaverRunning())
      setReturnUrlForCustom(location)
    })
  }, [])

  const headScripts = [
    analyticsEnabled &&
      !isAppReadOnly() && {
        src: analyticsUrl,
        async: '',
      },
  ].filter(Boolean)

  return (
    <VKeyboardProvider
      forceKeybordHide={forceKeybordHide}
      disabled={!useVKeyboard()}
      defaultSettings={{
        defaultKeyboard: getKeyboardLayout() || undefined,
        hideKeyboardLabel: t('TouchKeyboard.close'),
        coords: { x: 0, y: keyboardYCord },
      }}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <CourtesyChecker>
            <NavigationProvider>
              {headScripts && headScripts.length > 0 && <Helmet script={headScripts} />}
              <AppRouteActions />
              <Switch>
                <Route path="/retrieve-session/:sessionID" component={SessionHandoffRetriever} />
                <Route exact path={['/login', '/']} component={AuthChecker} />
                <Route exact path="/login-redirect" component={LoginRedirect} />
                <Route exact path="/login-bypass" component={LoginBypass} />
                <PrivateRoute path="/:store_type/:store_id/:lang" component={BaseWrapper} />
                <PrivateRoute path="/:store_type/:store_id" component={BaseWrapper} />
                <Route exact path="/service" render={() => <Service />} />
                <Route exact path="/config-override" render={() => <ConfigOverride />} />
                <Route exact path="/frame-advisor-handoff" component={FrameAdvisorHandoff} />
                <Route exact path={COURTESY_ROUTE} component={CourtesyPage} />
              </Switch>
            </NavigationProvider>
          </CourtesyChecker>
        </ConnectedRouter>
      </Provider>
    </VKeyboardProvider>
  )
}

export default App
