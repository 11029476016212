import { Modal } from 'components/core/Modal'
import styled from 'styled-components'
import { Device, landscape, tablet } from 'libs/media'
import { SingleImageContainer } from 'components/pdp/Images/styles'
import { Heading3, Heading5 } from 'components/Typography'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import { ModalBody, ModalFooter, ModalHeader } from 'components/core/Modal/styles'
import config from 'config'

export const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding: 1.09rem 2.18rem 2.73rem;
    width: 37.82rem;
  }
`

export const CustomLogoContainer = styled.div`
  height: 5.091rem;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const Description = styled.h5`
  margin: 2.182rem 0 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.909rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
`
export const Title = styled.h3<{
  isReducedMargin?: boolean
  isBoldTitle?: boolean
}>`
  margin: ${({ isReducedMargin }) => `${isReducedMargin ? 0.727 : 2.18}rem 0 2.18rem`};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.455rem;
  font-weight: ${({ isBoldTitle }) => (isBoldTitle ? 700 : 400)};
  line-height: 1.3125;
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
`

export const Subtitle = styled.h5`
  margin: 2.18rem 0 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.91rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
`

export const ImageContainer = styled.div`
  width: 24.77rem;
  height: 13.68rem;
  margin: 0 auto;
  position: relative;

  ${SingleImageContainer} {
    ${landscape(`
      width: inherit;
      height: inherit;
  `)}
  }

  & > * {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 2.182rem 0 0;

  > * {
    margin-right: 1.09rem;

    :last-child {
      margin-right: 0;
    }
  }
`
export const BarCodeScanModalSubTitle = styled(Heading5)`
  text-align: center;
  margin: ${pxToRem(48)} 0 0;
  ${tablet(
    `
    margin: ${clampValues(40, 48)} 0 0;
  `
  )}
  ${landscape(
    Device.tablet,
    `
    margin: ${clampValuesLandscape(40, 48)} 0 0;
  `
  )}
`

export const ImageModalProductImageContainer = styled.div`
  width: ${pxToRem(545)};
  height: ${pxToRem(301)};
  margin: 0 auto;
  position: relative;
  text-align: center;
  ${tablet(`
    height: ${clampValues(214, 301)};
    width: ${clampValues(388, 545)};
    img {
      width: inherit;
    }
  `)}
  ${landscape(
    Device.tablet,
    `
    height: ${clampValuesLandscape(214, 301)};
    width: ${clampValuesLandscape(388, 545)};
  `
  )}
`

export const LogoContainer = styled.div`
  height: ${pxToRem(132)};
  width: ${pxToRem(286)};
  margin: 0 auto;
  ${tablet(`
    height: ${pxToRem(112)};
    width: ${pxToRem(224)};
  `)}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: unset;
  }
`
export const BarCodeScanModalWrapper = styled(Modal)`
  .ant-modal-body {
    width: ${pxToRem(832)};
  }

  padding: 0;

  ${ModalHeader} {
    padding: 0;
    margin: 0;
    ${landscape(
      Device.tablet,
      `
        padding: 0;
    `
    )}
  }

  ${ModalBody} {
    ${tablet(
      `
      padding: ${clampValues(24, 44)} ${clampValues(52, 72)} ${clampValues(40, 60)};
      width: ${clampValues(650, 832)};
    `
    )}
    ${landscape(
      Device.tablet,
      `
      padding:
      ${clampValuesLandscape(14, 24)} ${clampValuesLandscape(52, 72)} ${clampValuesLandscape(
        40,
        60
      )};
      width: ${clampValuesLandscape(650, 832)};
    `
    )}
  }

  ${ModalWrapper} {
    ${tablet(
      `
      padding: 0;
    `
    )}
  }

  ${ModalFooter} {
    ${tablet(
      `
      padding: ${clampValues(42, 48)} 0 ${clampValues(22, 28)};
      > * {

      :last-child {
        margin-right: 0;
      }
    }
    `
    )}
    ${landscape(Device.tablet, `padding: ${clampValuesLandscape(42, 48)} 0 0;`)}
    button {
      min-width: ${pxToRem(261)};
      min-height: ${pxToRem(56)};
      height: auto !important;
      white-space: initial !important;
      padding-top: ${pxToRem(16)};
      padding-bottom: ${pxToRem(16)};
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
    }
  }
`

export const BarCodeScanModalDescription = styled(Heading5)`
  text-align: center;
  ${tablet(
    `
      margin: ${clampValues(40, 48)} 0 0;
  `
  )}
  ${landscape(
    Device.tablet,
    `
    margin: ${clampValuesLandscape(40, 48)} 0 0;
  `
  )}
`

const getTopMargin = (isReduced?: boolean) =>
  isReduced ? clampValues(10, 16) : clampValues(40, 48)

export const BarCodeScanModalTitle = styled(Heading3)<{
  isCapitalized?: boolean
  isReducedMargin?: boolean
}>`
  text-transform: ${({ isCapitalized }) => (isCapitalized ? 'capitalize' : 'none')};
  text-align: center;
  margin: ${({ isReducedMargin }) =>
    isReducedMargin ? `${pxToRem(16)} 0 ${pxToRem(48)}` : `${pxToRem(48)} 0`};
  ${({ isReducedMargin }) =>
    tablet(`
      margin: ${getTopMargin(isReducedMargin)} 0 ${clampValues(40, 48)};
    `)}
  ${({ isReducedMargin }) =>
    landscape(
      Device.tablet,
      `
      margin: ${getTopMargin(isReducedMargin)} 0 ${clampValues(40, 48)};
    `
    )}
`

export const RayBanCustomLogo = styled.img`
  content: url('${config.assets.URL}/customizeModalIcon.png');
`
