import formatPrice from 'libs/formatPrice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CartItem } from 'types/cart'
import { useStoreContext } from '../../hooks/useStoreContext'

interface MiniCartPriceProps {
  cartItem: CartItem
  showPrice?: boolean
  showVAT?: boolean
  showPrevious?: boolean
  quantity?: number
  showTextLabel?: boolean
  showSuggested?: boolean
  showQuantity?: boolean
  className?: string
}

export const Price = styled.div`
  text-align: right;
  font-size: 0.909rem;
  text-transform: capitalize;
`

const PreviousPrice = styled.div``

export const NoPrices = styled.div`
  text-align: right;
  text-transform: capitalize;
  font-size: 0.636rem;
  font-weight: 400;
`

export const NoPricesText = styled.p`
  margin-left: auto;
  font-weight: 400;
  font-style: italic;
  color: ${({ theme }) => theme.colors.secondaryText};
  white-space: normal;
  text-transform: capitalize;
  font-size: 0.636rem;
`

const SRPText = styled.p`
  margin-bottom: 0;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: 400;
  font-size: 0.636rem;
  font-style: italic;
`

const TextPrice = styled.span`
  font-weight: bold;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primaryText};
`

export const PriceContainer = styled.div`
  text-align: right;
`

const TextQuantity = styled.span`
  font-size: 0.909rem;
  color: ${({ theme }) => theme.colors.secondaryText};
`

export const MiniCartPrice: React.FC<MiniCartPriceProps> = ({
  cartItem,
  showVAT,
  showPrevious,
  quantity,
  showPrice,
  showTextLabel,
  showSuggested,
  showQuantity,
  className,
}) => {
  const { t } = useTranslation()
  const store = useStoreContext()
  const isVATExcluded = ['US', 'CA'].includes(store.countryId)

  return (
    <PriceContainer className={className}>
      {!showPrice || cartItem.product.price.current.value === 0 ? (
        <>
          <NoPrices>
            <NoPricesText>{t('SummaryCart.noPriceReferToSale')}</NoPricesText>
          </NoPrices>
          {showQuantity && (
            <TextQuantity data-test="text-qty">{`${t('Core.quantity')}: ${quantity}`}</TextQuantity>
          )}
        </>
      ) : (
        <>
          <TextPrice>
            {showTextLabel && <span className="text-price">{t('Cart.price-text')}:</span>}
            {formatPrice(
              quantity * cartItem.product.price.current.value,
              cartItem.product.price.currency
            )}
          </TextPrice>
          <br />
          <Price data-test="text-price">
            {showSuggested && <SRPText>{`${t('Cart.suggested-price')}`},</SRPText>}
            {showVAT && <SRPText>{isVATExcluded ? t('Cart.VATExcluded') : t('Cart.VAT')}</SRPText>}
            {showQuantity && (
              <TextQuantity data-test="text-qty">{`${t(
                'Core.quantity'
              )} ${quantity}`}</TextQuantity>
            )}
            {showPrevious && (
              <PreviousPrice data-test="text-price">
                {formatPrice(
                  cartItem.product.price.previous.value,
                  cartItem.product.price.currency
                )}
              </PreviousPrice>
            )}
          </Price>
        </>
      )}
    </PriceContainer>
  )
}
