import { setVisibleDigitalCouvette } from 'actions/ui'
import classnames from 'classnames'
import { CodeScannerModal } from 'components/CodeScanner'
import { OverPageLoading } from 'components/Contents/FrameAdvisorSuggestionContent/styles'
import { DigitalCouvette } from 'components/DigitalCouvette'
import { DrawerContainer } from 'components/Drawer/styles'
import { FloatingActions } from 'components/FloatingActions'
import Header from 'components/Header'
import { LogoColors } from 'components/Logo'
import PrivacyPolicyTooltip from 'components/MasterUI/PrivacyPolicyTooltip'
import { MenuDrawer } from 'components/MenuDrawer'
import { MiniCartDrawer } from 'components/MiniCartDrawer'
import { ScreenSaver } from 'components/ScreenSaver'
import { SearchDrawer } from 'components/SearchDrawer'
import { WarningModal } from 'components/WarningModal'
import { useCloseDrawerOnPageChange } from 'hooks/useCloseDrawerOnPageChange'
import { useConfigOverrideExpiration } from 'hooks/useConfigOverrideExpiration'
import { useDeviceType } from 'hooks/useDeviceType'
import { useHomePageChecker } from 'hooks/useHomePageChecker'
import { useReloadLangOnHistory } from 'hooks/useReloadLangOnHistory'
import { useSimulateFirstInteraction } from 'hooks/useSimulateFirstInteraction'
import { useStoreContext } from 'hooks/useStoreContext'
import { useToggleSearchDrawer } from 'hooks/useUrlParams'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import { getSiteAbsolutePath } from 'libs/url'
import { NavigationContext } from 'providers/navigationProvider'
import { useReset } from 'providers/resetProvider'
import { useGetI18n } from 'providers/translationProvider'
import { default as React, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { createGlobalStyle, css } from 'styled-components'
import { KioskSettingsOpener } from '../components/KioskAppClickCounterSection'
import { ErrorBoundary } from './ErrorBoundary'

const WarningModalWrapper = () => {
  const { t } = useTranslation()
  return <WarningModal warningText={t('CartModal.reloadOrderError')} />
}

const GlobalContainerCss = createGlobalStyle`
  #root,
  .vk-panel,
  .base-layout {
    height: 100%;
  }
   main.content {
    ${tablet('height: auto')}
    ${landscape('height: auto')}
  }

  ${DrawerContainer} {
    top: ${({ isAlternativeNavigation }) => isAlternativeNavigation && '56vh'};
    min-height: ${({ isAlternativeNavigation }) => isAlternativeNavigation && 'unset'};
  }
`

interface BaseContainerProps {
  header?: React.ReactNode
  className?: string
  disableDrawers?: boolean
  renderFilterDrawer?: JSX.Element
  showBackButton?: boolean
  logoColor?: LogoColors
  content: JSX.Element
  preventAccessibilityToggleResize?: boolean
  showHeader?: boolean
  showMenu?: boolean
  floatingHeader?: boolean
  withPadding?: boolean
  withoutBottomPadding?: boolean
  hasDynamicContentHeight?: boolean
  preventScroll?: boolean
  withHeight?: boolean
  showFrameAdvisorProfile?: boolean
  showPrivacyPolicyTooltip?: boolean
  fixedPrivacyPolicyIcon?: boolean
  showFrameAdvisorProductLoading?: boolean
  kioskAppSettingsOpener?: boolean
  showFilterButton?: boolean
  headerLogoUrl?: string
}

export interface ChildContainerProps {
  withPadding: boolean
  withoutBottomPadding?: boolean
  withHeight?: boolean
}

export const Main = styled.main<{
  hasDynamicContentHeight?: boolean
  preventScroll?: boolean
}>`
  position: relative;
  z-index: 0;
  flex: 1;
  overflow: ${({ preventScroll }) => (preventScroll ? 'hidden' : 'auto')};
  ${({ hasDynamicContentHeight }) =>
    tablet(`
    height: auto;
    overflow: unset;
    ${hasDynamicContentHeight ? 'display: table' : ''};
`)}
  ${({ hasDynamicContentHeight }) =>
    landscape(`
    height: auto;
    overflow: unset;
    ${hasDynamicContentHeight ? 'display: table' : ''};
`)}
`

export const baseContainerPadding = css`
  padding: 0 ${pxToRem(112)};
  ${tablet(`padding: 0 ${clampValues(72, 96)};`)}
  ${landscape(`padding: 0 ${pxToRem(112)};`)}
  ${landscape(Device.tablet, `padding: 0 ${clampValuesLandscape(72, 112)};`)}
`

export const baseContainerBottomPadding = css`
  padding-bottom: ${pxToRem(30)};
  ${tablet(`padding-bottom: ${pxToRem(30)};`)}
  ${landscape(`padding-bottom: ${pxToRem(30)};`)}
  ${landscape(Device.tablet, `padding-bottom: ${pxToRem(30)};`)}
`

export const ChildContainer = styled.div<{
  withPadding: boolean
  withoutBottomPadding?: boolean
  withHeight?: boolean
}>`
  min-height: 100%;
  width: 100vw;
  ${({ withHeight }) => (withHeight ? 'height: 100%' : '')};
  ${({ withPadding }) => withPadding && `${baseContainerPadding}`}
  ${({ withPadding, withoutBottomPadding }) =>
    withPadding && !withoutBottomPadding && `${baseContainerBottomPadding}`}
  ${landscape(
    Device.tablet,
    'contain:paint;'
  )} // fix ios horizontal elastic scrolling
  // see: https://stackoverflow.com/a/70722270
`

export const HeaderWrapper = styled.header<{ floatingHeader: boolean }>`
  position: ${({ floatingHeader }) => (floatingHeader ? 'absolute' : 'relative')};
  z-index: ${({ floatingHeader }) => (floatingHeader ? 1 : 0)};
  flex-shrink: 0;
  width: 100%;
  height: ${({ floatingHeader }) => (floatingHeader ? '0' : pxToRem(240))};
  ${tablet(`height: ${clampValues(95, 160)}`)}
  ${landscape(Device.tablet, `height: ${clampValuesLandscape(80, 115)}`)}
  ${({ floatingHeader }) => (floatingHeader ? '0' : `${landscape(`height: ${pxToRem(220)}`)}`)};
  ${({ floatingHeader }) =>
    floatingHeader ? '0' : `${landscape(Device.tablet, `height: ${pxToRem(154)}`)}`};
`

const ContainerStyled = styled.div<{
  isAlternativeNavigation?: boolean
  preventAccessibilityToggleResize?: boolean
  preventScroll?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ preventScroll }) => preventScroll && 'overflow: hidden;'}
  ${({ preventScroll }) =>
    tablet(`
  flex: 1;
  overflow: ${preventScroll ? 'hidden;' : 'scroll'}
`)}
  ${({ preventScroll }) =>
    landscape(`
  flex: 1;
  overflow: ${preventScroll ? 'hidden;' : 'scroll'}
`)}
`

const FirstInteractionScreen = styled.div`
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const BaseContainer = React.forwardRef(
  (
    {
      showHeader = true,
      disableDrawers = false,
      renderFilterDrawer,
      header,
      content,
      className,
      showMenu = true,
      showBackButton = true,
      preventAccessibilityToggleResize = false,
      floatingHeader = true,
      withPadding = true,
      withoutBottomPadding = false,
      hasDynamicContentHeight = false,
      logoColor,
      preventScroll = false,
      withHeight = true,
      showFrameAdvisorProfile = false,
      showPrivacyPolicyTooltip = true,
      fixedPrivacyPolicyIcon = false,
      showFrameAdvisorProductLoading = false,
      kioskAppSettingsOpener = false,
      showFilterButton = true,
      headerLogoUrl,
    }: BaseContainerProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation()
    const SCROLL_RESTORATION_ID = 'page'
    const headerComponent = !showHeader
      ? null
      : header || (
          <Header
            logoColor={logoColor}
            showFrameAdvisorProfile={showFrameAdvisorProfile}
            logoUrl={headerLogoUrl}
          />
        )
    const store = useStoreContext()
    const { isTowerPortrait } = useDeviceType()
    const mainClassName = `content${!showHeader ? ' no-header' : ''}`
    const { isHomePage } = useHomePageChecker()
    const { isAlternativeNavigation } = useContext(NavigationContext)
    const { userInteractedAfterReset } = useReset()
    const visibleDigitalCouvette = useSelector(s => s.ui.visibleDigitalCouvette)
    const dispatch = useDispatch()

    const [clientX, setClientX] = useState<number | undefined>()
    const [clientY, setClientY] = useState<number | undefined>()

    const handleFirstInteractionScreenClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setClientX(e.clientX)
      setClientY(e.clientY)
    }

    useCloseDrawerOnPageChange()
    useConfigOverrideExpiration()
    useReloadLangOnHistory() // TODO: do we need it?
    useToggleSearchDrawer()

    useSimulateFirstInteraction(clientX, clientY)
    const i18n = useGetI18n()

    useEffect(() => {
      const currentLang = i18n.language.split('-')[0]
      const langFixPrefix = 'lang-fix-'
      if (!new RegExp(`${langFixPrefix}${currentLang}`).test(document.body.className)) {
        document.body.className = document.body.className.replace(
          new RegExp(`${langFixPrefix}[a-z]{2}`),
          ''
        )
        document.body.classList.add(`${langFixPrefix}${currentLang}`)
      }
    }, [i18n.language])

    useCloseDrawerOnPageChange()
    useConfigOverrideExpiration()
    useReloadLangOnHistory()
    useToggleSearchDrawer()

    useSimulateFirstInteraction(clientX, clientY)

    useEffect(() => {
      const currentLang = i18n.language.split('-')[0]
      const langFixPrefix = 'lang-fix-'
      if (!new RegExp(`${langFixPrefix}${currentLang}`).test(document.body.className)) {
        document.body.className = document.body.className.replace(
          new RegExp(`${langFixPrefix}[a-z]{2}`),
          ''
        )
        document.body.classList.add(`${langFixPrefix}${currentLang}`)
      }

      const digitalCatlogPrefix = 'digital-catalog-'

      document.body.className.replace(`/${digitalCatlogPrefix}*/g`, '')
      document.body.classList.add(
        `${digitalCatlogPrefix}${store.digitalCatalog ? 'enabled' : 'disabled'}`
      )
    }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

    return (
      <>
        <GlobalContainerCss isAlternativeNavigation={isAlternativeNavigation} />
        <ErrorBoundary actionUrl={getSiteAbsolutePath()} t={t}>
          <ContainerStyled
            preventScroll={preventScroll}
            className={classnames('base-layout', className)}
            isAlternativeNavigation={isAlternativeNavigation}
            preventAccessibilityToggleResize={preventAccessibilityToggleResize}
            id={SCROLL_RESTORATION_ID}
            {...(!isTowerPortrait && { ref })}
          >
            {headerComponent && (
              <HeaderWrapper floatingHeader={floatingHeader}>
                {headerComponent}
                {kioskAppSettingsOpener && <KioskSettingsOpener />}
              </HeaderWrapper>
            )}
            <Main
              preventScroll={preventScroll}
              className={mainClassName}
              id="main"
              hasDynamicContentHeight={hasDynamicContentHeight}
              {...(isTowerPortrait && { ref })}
            >
              <ChildContainer
                withPadding={withPadding}
                withoutBottomPadding={withoutBottomPadding}
                withHeight={withHeight}
                className={className}
              >
                {content}
              </ChildContainer>
            </Main>

            {showPrivacyPolicyTooltip && (
              <PrivacyPolicyTooltip stroke={logoColor} isFixed={fixedPrivacyPolicyIcon} />
            )}

            {!store.screensaverEnabled && isHomePage && !userInteractedAfterReset && (
              <FirstInteractionScreen onClick={handleFirstInteractionScreenClick} />
            )}
            {store.screensaverEnabled && (
              <ScreenSaver
                store={store}
                autostart
                isIdleStatus={!userInteractedAfterReset}
                onClick={
                  isHomePage && !userInteractedAfterReset
                    ? handleFirstInteractionScreenClick
                    : undefined
                }
              />
            )}

            {showMenu && (
              <FloatingActions
                showBackButton={showBackButton}
                showFilterButton={showFilterButton}
              />
            )}

            {!disableDrawers && (
              <>
                {renderFilterDrawer}
                <MenuDrawer />
                <MiniCartDrawer />
                <SearchDrawer />
              </>
            )}

            <WarningModalWrapper />
            <CodeScannerModal store={store} />
            <DigitalCouvette
              isVisible={visibleDigitalCouvette}
              onCancel={() => dispatch(setVisibleDigitalCouvette(false))}
            />

            {showFrameAdvisorProductLoading && <OverPageLoading />}
          </ContainerStyled>
        </ErrorBoundary>
      </>
    )
  }
)

BaseContainer.displayName = 'BaseContainer'

export default BaseContainer
