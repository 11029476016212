import {
  QueryFunctionOptions,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { QueryControls } from '@apollo/client/react/hoc'
import { BarcodeProducts } from 'components/BarCodeScanModal/types'
import config from 'config'
import { useStoreContext } from 'hooks/useStoreContext'
import { DEFAULT_LANG, useStoreIndentity } from 'hooks/useStoreIdentity'
import { checkUserMigrated } from 'libs/auth'
import queryString from 'qs'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { CheckoutState } from 'reducers/checkout'
import { AboutThisStyle } from 'types/aboutThisStyle'
import { CartInformation, CustomerAddress, OrderItem, ShippingMethods } from 'types/checkoutV3'
import { CustomModel } from 'types/custom'
import { FilterFacet, FilterInput } from 'types/filter'
import { FitSize } from 'types/fitSize'
import {
  ColorPalettes,
  FrameAdvisorSurvey,
  FrameAdvisorUndertones,
  FrameAdvisorUserProfile,
  SizeAdvisorData,
} from 'types/frameAdvisor'
import {
  BipaConsentsResponse,
  CheckoutError,
  ContentHtml,
  ContentV2Electronics,
  ContentV2PlaylistItem,
  ContentV2PrizmItem,
  ContentV2Video,
  EnforcementMessagesInfo,
  FrameAdvisorFsaRegulationsArgs,
  FrameAdvisorFsaRegulationsType,
  FrameAdvisorOnboarding,
  GreenShipmentInfo,
  Language,
  Maybe,
  MutationFrameAdvisorFsaBipaConsentsArgs,
  MutationFrameAdvisorFsaCreateUserProfileArgs,
  NuanceTabContent,
  ProductRelation,
  QueryFrameAdvisorFsaUserProfileArgs,
} from 'types/graphqlTypes'
import { Image } from 'types/image'
import { Product } from 'types/product'
import { SessionHandoffCheckProducts } from 'types/sessionHandoffCheckProducts'
import { Store } from 'types/store'
import apolloClient from './apolloClient'
import ABOUT_THIS_STYLE_QUERY from './graphql/AboutThisStyle.graphql'
import ADDRESS_AUTO_COMPLETE_CONFIG from './graphql/AddressAutoCompleteConfig.graphql'
import BARCODE_PRODUCTS_QUERY from './graphql/BarcodeProducts.graphql'
import CART_INFORMATION from './graphql/CartInformation.graphql'
import CUSTOMER_ADDRESSES from './graphql/CustomerAddresses.graphql'
import RB_CUSTOM_MODELS from './graphql/CustomModelsRB.graphql'
import ENFORCEMENT_MESSAGES_QUERY from './graphql/EnforcementMessages.graphql'
import FIT_SIZE_QUERY from './graphql/FitSize.graphql'
import FRAME_ADVISOR_COLOR_PALETTES from './graphql/FrameAdvisorColorPalettes.graphql'
import FRAME_ADVISOR_FSA_TOKEN from './graphql/FrameAdvisorFsaToken.graphql'
import FRAME_ADVISOR_FSA_USER_PROFILE from './graphql/FrameAdvisorFsaUserProfile.graphql'
import FRAME_ADVISOR_ONBOARDING from './graphql/FrameAdvisorOnboarding.graphql'
import FRAME_ADVISOR_SUGGESTION_QUERY from './graphql/FrameAdvisorProducts.graphql'
import FRAME_ADVISOR_UNDERTONES_QUERY from './graphql/FrameAdvisorUndertones.graphql'
import FRAME_MODELS_DC_QUERY from './graphql/FrameModelsDC.graphql'
import GREEN_SHIPMENT_INFO_QUERY from './graphql/GreenShipmentInfo.graphql'
import INSPIRED_PRODUCTS from './graphql/InspiredProducts.graphql'
import CART_ITEM_NOTE from './graphql/mutations/cartItemNote.graphql'
import CHECKOUT_QUERY from './graphql/mutations/checkout.graphql'
import CREATEVMHASH_QUERY from './graphql/mutations/createVMHash.graphql'
import UPDATE_ADDRESS from './graphql/mutations/customerCartAddress.graphql'
import DELETE_ORDER_ITEM from './graphql/mutations/deleteOrderItem.graphql'
import DELETE_SHIP_TO_CONSUMER_ADDRESS from './graphql/mutations/deleteShipToConsumerAddress.graphql'
import FRAME_ADVISOR_FSA_BIPA_CONSENTS from './graphql/mutations/frameAdvisorFsaBipaConsents.graphql'
import FRAME_ADVISOR_FSA_CONSENT from './graphql/mutations/frameAdvisorFsaConsent.graphql'
import FRAME_ADVISOR_FSA_EMAIL from './graphql/mutations/frameAdvisorFsaEmail.graphql'
import FRAME_ADVISOR_FSA_REGULATIONS from './graphql/mutations/frameAdvisorFsaRegulations.graphql'
import FRAME_ADVISOR_FSA_CREATE_USER_PROFILE from './graphql/mutations/fsaCreateUser.graphql'
import FSA_UPDATE_PROFILE_PROPERTY from './graphql/mutations/fsaUpdateProfileProperty.graphql'
import LOGIN_QUERY from './graphql/mutations/login.graphql'
import SESSION_HANDOFF_STATE_SAVER from './graphql/mutations/sessionHandoffStateSaver.graphql'
import SHIP_TO_CONSUMER_ADDRESS from './graphql/mutations/shipToConsumerAddress.graphql'
import SUBMIT_ORDER from './graphql/mutations/submitOrder.graphql'
import UPDATE_ORDER_ITEM from './graphql/mutations/updateOrderItem.graphql'
import UPDATE_SHIP_TO_CONSUMER_ADDRESS from './graphql/mutations/updateShipToConsumerAddress.graphql'
import NUANCE_TAB_CONTENT from './graphql/NuanceTabContent.graphql'
import ORDER_RECAP from './graphql/OrderRecap.graphql'
import PAGE_QUERY from './graphql/Page.graphql'
import PRODUCTS_QUERY from './graphql/Products.graphql'
import PRODUCTS_BY_UPC from './graphql/productsByUpc.graphql'
import SESSION_HANDOFF_CHECK_PRODUCTS_QUERY from './graphql/SessionHandoffCheckProducts.graphql'
import SESSION_HANDOFF_STATE from './graphql/SessionHandoffState.graphql'
import SESSION_HANDOFF_TOKEN from './graphql/SessionHandoffToken.graphql'
import SESSION_HANDOFF_TOKEN_STATUS_CHECK from './graphql/SessionHandoffTokenStatusCheck.graphql'
import SHIPPING_METHODS from './graphql/ShippingMethods.graphql'
import SIZE_ADVISOR from './graphql/SizeAdvisor.graphql'
import STORE_QUERY from './graphql/Store.graphql'
import TRANSLATIONS_QUERY from './graphql/Translations.graphql'
import { getPLPFiltersFromLocation } from './libs/url'

export const languages: Language[] = [
  { id: 'en-US', label: 'English', isoCode: 'en-US' },
  { id: 'fr-FR', label: 'French', isoCode: 'fr-FR' },
  { id: 'it-IT', label: 'Italian', isoCode: 'it-IT' },
  { id: 'es-ES', label: 'Español', isoCode: 'es-ES' },
  { id: 'de-DE', label: 'Deutsch', isoCode: 'de-DE' },
  { id: 'ja-JP', label: 'Japanese', isoCode: 'ja-JP' },
  { id: 'pt-PT', label: 'Portuguese', isoCode: 'pt-PT' },
  { id: 'pt-BR', label: 'Brazilian', isoCode: 'pt-BR' },
  { id: 'tr-TR', label: 'Turkish', isoCode: 'tr-TR' },
  { id: 'pl-PL', label: 'Polish', isoCode: 'pl-PL' },
  { id: 'hu-HU', label: 'Hungarian', isoCode: 'hu-HU' },
  { id: 'ro-RO', label: 'Romanian', isoCode: 'ro-RO' },
  { id: 'hr-HR', label: 'Croatian', isoCode: 'hr-HR' },
  { id: 'sl-SI', label: 'Slovenian', isoCode: 'sl-SI' },
  { id: 'nl-NL', label: 'Dutch', isoCode: 'nl-NL' },
  { id: 'cs-CZ', label: 'Czech', isoCode: 'cs-CZ' },
  { id: 'sk-SK', label: 'Slovakian', isoCode: 'sk-SK' },
  { id: 'lv-LV', label: 'Latvian', isoCode: 'lv-LV' },
  { id: 'lt-LT', label: 'Lithuanian', isoCode: 'lt-LT' },
  { id: 'et-EE', label: 'Estonian', isoCode: 'et-EE' },
  { id: 'zh-cn', label: 'Chinese', isoCode: 'zh-CN' },
  { id: 'bg-BG', label: 'Bulgarian', isoCode: 'bg-BG' },
  { id: 'zh-HK', label: 'Chinese_Traditional', isoCode: 'zh-HK' },
]

export type PageTypes =
  | 'PageHome'
  | 'PageGeneric'
  | 'PagePLP'
  | 'PagePDP'
  | 'PageStella'
  | 'PageSuperNova'
  | 'PagePrizm'
  | 'PageFrameAdvisorPlaylist'
  | 'PageFrameAdvisorSurvey'
  | 'PageNuance'

export interface StoreDataQueryResult {
  store: Store
}

export interface PageElementLink {
  url: string
  label: string
}

export interface PageElement {
  id: string
  title: string
  link: PageElementLink
  image: Image
  __typename?: string
}

export interface PageDataQueryResult extends QueryControls {
  page: {
    content: PageElement[]
  }
}
export interface FrameAdvisorPlaylistQueryResult {
  page: {
    id: string
    __typename: string
    playlist: {
      title: string
      playlist: ContentV2PlaylistItem[]
    }
  }
}

export interface ProductDataQueryResult {
  products: {
    __typename?: string
    items: Product[]
    numRows: number
    filters: FilterFacet[]
  }
}

export interface ProductDataQueryResultDC {
  model: string
  brand: string
  modelName: string
  hingeToHinge: number
  caliber: string
  bridge: string
}

export interface ProductSku {
  id: string
  UPC: string
  url: string
}

export interface SearchedCustomModelGroup {
  searchedCustomModel: CustomModel
  customModels: CustomModel[]
}

export interface ProductsByUpcDataQueryResult {
  productsByUpc: {
    __typename?: string
    items: ProductSku[]
    similarProducts: Product[]
    searchedCustomModelGroup?: SearchedCustomModelGroup
  }
}

export interface BarcodeProductsResData {
  barcodeProducts: BarcodeProducts // TODO: replace with BarcodeProducts from graphqlTypes
}

export interface PDPDataQueryResult {
  page: {
    __typename?: string
    id: string
    alternatives: Product[]
    product: Product
    relationship?: Maybe<ProductRelation>
  }
}

export interface InspiredDataQueryResult {
  inspiredProducts: string[]
}

export interface ElectronicsDataQueryResult {
  page: {
    __typename?: string
    id: string
    content: ContentV2Electronics[]
  }
}

export interface LoginMutationResultData {
  login: {
    url: string
    token: string
    error: ResponseError
  }
}

export interface ResponseError {
  status: number
  statusText: string
  message: string
}

export interface CheckoutMutationResultData {
  checkout: {
    orderNumber?: string
    userToken?: string
    errors?: ResponseError[]
  }
}

export interface PrivacyPolicyDataQueryResult {
  page: {
    content: ContentHtml[]
    id: string
    title: string | null
    __typename: string
  }
}

export interface TermsAndConditionsDataQueryResult {
  page: {
    content: ContentHtml[]
    id: string
    title: string | null
    __typename: string
  }
}

export interface PrizmQueryResult {
  page: {
    id: string
    __typename: string
    prizmPlaylist: { playlist: ContentV2PrizmItem[] }
  }
}

export interface CustomerAddressQueryResult {
  customerAddress: {
    listOfCustomerAddresses: CustomerAddress[]
  }
}

interface ShipToConsumerInfoArgs {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface ShipToConsumerMethodArgs {
  shipMethodCode: string
}

interface ShipToConsumerAddressArgs {
  address: string
  address1: string
  zipCode: string
  city: string
  state: string
  country: string
  addressId?: string
}

interface DeleteShipToConsumerMethodArgs {
  addressId: string
}

interface DeleteShipToConsumerResultData {
  success: boolean
}

export interface AddressAutoCompleteConfigQueryResult {
  apiKey: string
  autoCompleteConfigs: AutoCompleteConfig[]
}

interface AutoCompleteConfig {
  geoCountry: string
  fieldRules?: FieldRules
}

interface FieldRules {
  country: string
  state: string
  zipCode: string
  city: string
  streetName: string
  streetNumber: string
}

export interface ShipToConsumerAddressQueryResult {
  shipToConsumerAddress: {
    addressId: string
    success: boolean
    validation: AddressValidation
  }
}

interface AddressValidation {
  valid: boolean
  errorType: string[]
}

type ResultData<TExtendedFields> = {
  result: string
  errorMessage?: string
} & TExtendedFields

export interface UpdateAddressMutationResultData<TExtendedFields> {
  customerCartAddress: ResultData<TExtendedFields>
}

export interface SubmitOrderResultData {
  submitOrder: {
    success: boolean
    errors: CheckoutError[]
  }
}

export interface UpdateOrderItemResultData<TExtendedFields> {
  updateCartItem: ResultData<TExtendedFields>
}

export interface DeleteOrderItemResultData<TExtendedFields> {
  deleteCartItem: ResultData<TExtendedFields>
}

export interface CartItemNoteResultData<TExtendedFields> {
  cartItemNote: ResultData<TExtendedFields>
}

export interface CustomModelsRBQueryResult {
  customModelsRB: CustomModel[]
}

interface AboutThisStyleResultData {
  aboutThisStyle: AboutThisStyle
}

interface SessionHandoffCheckProductsResultData {
  sessionHandoffCheckProducts: SessionHandoffCheckProducts
}

interface EnforcementMessagesResultData {
  enforcementMessagesInfo: EnforcementMessagesInfo
}
interface FitSizeContentResultData {
  fitSize: FitSize
}

interface FrameAdvisorFsaToken {
  frameAdvisorFsaToken: {
    frameAdvisorFsaToken: {
      token: string
      postURL: string
    }
    videoBg: ContentV2Video[]
  }
}

interface FrameAdvisorFsaUserProfile {
  frameAdvisorFsaUserProfile: FrameAdvisorUserProfile
}

interface FrameAdvisorFsaCreateUserProfile {
  fsaCreateUser: {
    uuid: string
    accessToken: string
  }
}

interface FrameAdvisorOnboardingResultData {
  frameAdvisorOnboarding?: FrameAdvisorOnboarding
}

interface FsaUpdateProfilePropertyData {
  fsaUpdateProfileProperty: boolean
}

interface SizeAdvisorResultData {
  sizeAdvisor?: SizeAdvisorData
}

interface ColorPalettesResultData {
  frameAdvisorColorPalettes?: ColorPalettes
}

interface UndertonesResultData {
  frameAdvisorUndertones?: FrameAdvisorUndertones
}

export interface FrameAdvisorSurveyQueryResult {
  page: {
    id: string
    __typename: string
    survey: {
      survey: FrameAdvisorSurvey
    }
    videoBg: ContentV2Video[]
  }
}

interface FrameAdvisorSuggestionMutationResultData {
  frameAdvisorSuggestions: {
    products: Product[]
    numFound: number
    filters: FilterFacet[]
    __typename: string
  }
}

interface LabelsTranslations {
  [groupKey: string]: string | LabelsTranslations
}

interface Translations {
  data: LabelsTranslations
  error: ResponseError
}

interface TranslationsResultData {
  translations: Translations
}

export const useStoreData = () => {
  let { pathname } = location
  const urlparts = pathname.split('/')
  const [, storeType, storeId, storeLang] = urlparts
  const isMigrated = checkUserMigrated()
  return useQuery<StoreDataQueryResult>(STORE_QUERY, {
    variables: {
      url: `${storeType}/${storeId}/${storeLang || DEFAULT_LANG}`,
      isMigrated,
    },
    fetchPolicy: 'network-only',
  })
}

const usePageQueryOptions = (
  pageType: string,
  pathName: string,
  tags?: Record<string, Maybe<string>>,
  forceSearch?: boolean
) => {
  const isUserMigrated = checkUserMigrated()
  const { search } = location

  return {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: pathName,
      type: pageType,
      search: forceSearch ? '' : search,
      isMigrated: isUserMigrated,
      tags,
    },
    fetchPolicy: 'cache-first' as const,
  }
}

export const usePageData = <T>(
  pageType: PageTypes,
  tags?: Record<string, Maybe<string>>,
  forceSearch = false
) => {
  const { pathname } = useLocation()
  const queryOptions = usePageQueryOptions(pageType, pathname, tags, forceSearch)
  return useQuery<T>(PAGE_QUERY, queryOptions)
}

export const useFrameAdvisorSurveyPageData = <T>(pageType: PageTypes) => {
  const match = useRouteMatch()
  const pathname = match.path.replace('/:stepId?', '')
  const queryOptions = usePageQueryOptions(pageType, pathname)
  return useQuery<T>(PAGE_QUERY, queryOptions)
}
export const usePrivacyPolicyPageData = <T>(pageType: PageTypes) => {
  const { basePath } = useStoreIndentity()
  const pathname = `${basePath}/vm-privacy-policy`
  const queryOptions = usePageQueryOptions(pageType, pathname)
  return useLazyQuery<T>(PAGE_QUERY, queryOptions)
}

export const useTermsAndConditionsPageData = <T>(pageType: PageTypes) => {
  const { basePath } = useStoreIndentity()
  const pathname = `${basePath}/privacy-terms`
  const queryOptions = usePageQueryOptions(pageType, pathname)
  return useLazyQuery<T>(PAGE_QUERY, queryOptions)
}

export const useLazyPageData = <T>(pageType: PageTypes) => {
  const { pathname } = useLocation()
  const queryOptions = usePageQueryOptions(pageType, pathname)
  return useLazyQuery<T>(PAGE_QUERY, queryOptions)
}

export const useFitSizeContent = () => {
  const { location } = useHistory()
  const sessionId = useSelector(s => s.session.token)
  const { pathname } = location

  return useQuery<FitSizeContentResultData>(FIT_SIZE_QUERY, {
    variables: {
      url: pathname,
    },
    context: {
      headers: {
        'dw-session-id': sessionId,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

const useProductQuery = (
  query?: string,
  forceFilters?: FilterInput[],
  tags?: Record<string, Maybe<string>>,
  limit: number = config.itemsPerPage
): QueryHookOptions => {
  const { location } = useHistory()
  let { brand } = useParams<{ brand?: string }>()

  const { pathname, search } = location
  const { q, sort, comesFromBarcode } = queryString.parse(search, { ignoreQueryPrefix: true })

  const fallbackQuery = comesFromBarcode ? '' : q

  let brandFilter
  if (brand) {
    brandFilter = {
      name: 'brand',
      value: brand.split('%2C'),
    }
  }

  let filters = getPLPFiltersFromLocation(location)
  if (brandFilter) {
    if (filters) {
      filters.push(brandFilter)
    } else {
      filters = [brandFilter]
    }
  }
  return {
    variables: {
      url: pathname,
      // TODO: from NoDataContent query comes as empty string. If we sent the query value to the api we receive no items for bestsellers.
      //  Is there better decision then this fallbackQuery?
      query: query ? query : fallbackQuery,
      sort,
      filters: forceFilters?.length ? forceFilters : filters,
      page: 1,
      tags,
      limit,
    },
    fetchPolicy: 'network-only',
  }
}

export const useCartInformation = () => {
  return useQuery<{
    cartInformation: CartInformation
  }>(CART_INFORMATION, {
    fetchPolicy: 'network-only',
  })
}

export const useCartAddresses = (storeId: string, username: string) => {
  return useQuery<CustomerAddressQueryResult>(CUSTOMER_ADDRESSES, {
    variables: { storeId, username },
  })
}

export const useShippingMethods = () => {
  const store = useStoreContext()
  const skip = useMemo(() => !store.shipToConsumerEnabled, [store.shipToConsumerEnabled])
  const queryResult = useQuery<ShippingMethods>(SHIPPING_METHODS, {
    skip,
  })
  const { refetch } = queryResult
  useEffect(() => {
    !skip && refetch()
  }, [skip, refetch])
  return queryResult
}

export const useShipToConsumerAddress = () => {
  return useMutation<ShipToConsumerAddressQueryResult>(SHIP_TO_CONSUMER_ADDRESS)
}

export const useUpdateShipToConsumerAddress = (
  shipToConsumerInfo: ShipToConsumerInfoArgs,
  shipToConsumerAddress: ShipToConsumerAddressArgs,
  shipToConsumerMethod: ShipToConsumerMethodArgs
) => {
  return useMutation<ShipToConsumerAddressQueryResult>(UPDATE_SHIP_TO_CONSUMER_ADDRESS, {
    variables: {
      shipToConsumerInfo,
      shipToConsumerAddress,
      shipToConsumerMethod,
    },
  })
}

export const useDeleteShipToConsumerAddress = (args: DeleteShipToConsumerMethodArgs) => {
  return useMutation<DeleteShipToConsumerResultData>(DELETE_SHIP_TO_CONSUMER_ADDRESS, {
    variables: args,
  })
}

export const useAddressAutoCompleteConfig = () => {
  return useQuery<{ addressAutoCompleteConfig: AddressAutoCompleteConfigQueryResult }>(
    ADDRESS_AUTO_COMPLETE_CONFIG
  )
}

export const useProductData = (tags?: Record<string, Maybe<string>>) => {
  const queryOptions = useProductQuery('', undefined, tags)
  return useQuery<ProductDataQueryResult>(PRODUCTS_QUERY, queryOptions)
}

export const useBarcodeProducts = (upc: string) => {
  const { pathname } = location
  return useQuery<BarcodeProductsResData>(BARCODE_PRODUCTS_QUERY, {
    variables: {
      url: pathname,
      search: upc,
    },
    fetchPolicy: 'network-only',
  })
}
export const useSearchData = (query: string, filters?: FilterInput[], limit?: number) => {
  const queryOptions = useProductQuery(query, filters, {}, limit)
  return useQuery<ProductDataQueryResult>(PRODUCTS_QUERY, queryOptions)
}

export const useFrameModelsDC = () => {
  return useQuery<{ digitalCouvetteFrames: ProductDataQueryResultDC[] }>(FRAME_MODELS_DC_QUERY)
}

export const useRBCustomModels = (
  options: Partial<QueryHookOptions<CustomModelsRBQueryResult>> = {}
) => {
  const { basePath } = useStoreIndentity()

  return useQuery<CustomModelsRBQueryResult>(RB_CUSTOM_MODELS, {
    variables: {
      url: basePath,
    },
    fetchPolicy: 'cache-first',
    ...options,
  })
}

export const useInspiredData = (modelCode: string) => {
  const { pathname } = location
  return useQuery<InspiredDataQueryResult>(INSPIRED_PRODUCTS, {
    variables: {
      url: pathname,
      search: modelCode,
    },
    fetchPolicy: 'cache-first',
  })
}

export const useProductsByUpcData = (
  upc: string,
  fetchPolicy: WatchQueryFetchPolicy = 'network-only'
) => {
  const { pathname } = location
  return useQuery<ProductsByUpcDataQueryResult>(PRODUCTS_BY_UPC, {
    variables: {
      url: pathname,
      search: upc,
    },
    fetchPolicy,
  })
}

export const useAboutThisStyle = (brand: string, model: string, isUserMigrated: boolean) => {
  const { basePath } = useStoreIndentity()
  const sessionID = useSelector(s => s.session.token)

  return useQuery<AboutThisStyleResultData>(ABOUT_THIS_STYLE_QUERY, {
    variables: {
      url: basePath,
      brand,
      model,
      isUserMigrated,
    },
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useSessionHandoffCheckProductsQuery = (models: string[]) => {
  const { basePath } = useStoreIndentity()
  const sessionID = useSelector(s => s.session.token)
  const fromOldSession = history.state?.state?.fromOldSession

  return useQuery<SessionHandoffCheckProductsResultData>(SESSION_HANDOFF_CHECK_PRODUCTS_QUERY, {
    variables: {
      url: basePath,
      models,
    },
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !fromOldSession || models?.length === 0,
  })
}

export const useFrameAdvisorFsaUserProfile = (userId?: string, fromSurvey = false) => {
  const sessionID = useSelector(s => s.session.token)
  const captureComponentAccessToken = useSelector(s =>
    fromSurvey ? s.frameAdvisor.surveyAccessToken : s.frameAdvisor.faceScanAccessToken
  )
  const captureComponentRefreshToken = useSelector(s =>
    fromSurvey ? s.frameAdvisor.surveyRefreshToken : s.frameAdvisor.faceScanRefreshToken
  )

  return useQuery<FrameAdvisorFsaUserProfile, QueryFrameAdvisorFsaUserProfileArgs>(
    FRAME_ADVISOR_FSA_USER_PROFILE,
    {
      context: {
        headers: {
          'dw-session-id': sessionID,
        },
      },
      variables: {
        userId,
        fromSurvey,
        captureComponentTokens: {
          captureComponentAccessToken,
          captureComponentRefreshToken,
        },
      },
      fetchPolicy: 'cache-first',
      skip: !userId,
    }
  )
}

export const useFrameAdvisorFsaCreateUserProfile = (fromSurvey = false) => {
  const { basePath } = useStoreIndentity()
  const sessionID = useSelector(s => s.session.token)
  const captureComponentAccessToken = useSelector(s =>
    fromSurvey ? s.frameAdvisor.surveyAccessToken : s.frameAdvisor.faceScanAccessToken
  )
  const captureComponentRefreshToken = useSelector(s =>
    fromSurvey ? s.frameAdvisor.surveyRefreshToken : s.frameAdvisor.faceScanRefreshToken
  )
  return useMutation<
    FrameAdvisorFsaCreateUserProfile,
    MutationFrameAdvisorFsaCreateUserProfileArgs
  >(FRAME_ADVISOR_FSA_CREATE_USER_PROFILE, {
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
    variables: {
      url: basePath,
      captureComponentTokens: {
        captureComponentAccessToken,
        captureComponentRefreshToken,
      },
    },
  })
}

export const useFrameAdvisorBipaConsents = () => {
  const sessionID = useSelector(s => s.session.token)

  return useMutation<BipaConsentsResponse, MutationFrameAdvisorFsaBipaConsentsArgs>(
    FRAME_ADVISOR_FSA_BIPA_CONSENTS,
    {
      context: {
        headers: {
          'dw-session-id': sessionID,
        },
      },
    }
  )
}

export const useFrameAdvisorRegulations = (type: FrameAdvisorFsaRegulationsType) => {
  const { basePath } = useStoreIndentity()
  const sessionID = useSelector(s => s.session.token)

  return useQuery<{ frameAdvisorRegulations: string }, FrameAdvisorFsaRegulationsArgs>(
    FRAME_ADVISOR_FSA_REGULATIONS,
    {
      variables: {
        url: basePath,
        type,
      },
      context: {
        headers: {
          'dw-session-id': sessionID,
        },
      },
      fetchPolicy: 'cache-first',
    }
  )
}

export const getTranslations = (lang: string) => {
  return apolloClient.query<TranslationsResultData>({
    query: TRANSLATIONS_QUERY,
    variables: {
      lang,
    },
    fetchPolicy: 'cache-first',
  })
}

export const useFrameAdvisorOnboarding = () => {
  const { basePath } = useStoreIndentity()
  return useQuery<FrameAdvisorOnboardingResultData>(FRAME_ADVISOR_ONBOARDING, {
    variables: {
      url: basePath,
    },
  })
}

export const useEnforcementMessagesInfo = () => {
  const { basePath } = useStoreIndentity()
  const sessionID = useSelector(s => s.session.token)

  return useQuery<EnforcementMessagesResultData>(ENFORCEMENT_MESSAGES_QUERY, {
    variables: {
      url: basePath,
    },
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useDoLogin = () => {
  return useMutation<LoginMutationResultData>(LOGIN_QUERY)
}

export const useVMHash = () => {
  return useMutation(CREATEVMHASH_QUERY)
}

export const useDoCheckout = () => {
  return useMutation<CheckoutMutationResultData>(CHECKOUT_QUERY)
}

export const useDoUpdateAddress = <TExtendedFields>() => {
  return useMutation<UpdateAddressMutationResultData<TExtendedFields>>(UPDATE_ADDRESS)
}

export const useDoSubmitOrder = () => {
  return useMutation<SubmitOrderResultData>(SUBMIT_ORDER)
}

export const useDoUpdateOrderItem = <TExtendedFields>() => {
  return useMutation<UpdateOrderItemResultData<TExtendedFields>>(UPDATE_ORDER_ITEM)
}

export const useDoDeleteOrderItem = <TExtendedFields>() => {
  return useMutation<DeleteOrderItemResultData<TExtendedFields>>(DELETE_ORDER_ITEM)
}

export const useDoCartItemNote = <TExtendedFields>() => {
  return useMutation<CartItemNoteResultData<TExtendedFields>>(CART_ITEM_NOTE)
}

export const useFrameAdvisorFsaToken = () => {
  const sessionID = useSelector(s => s.session.token)
  const { basePath } = useStoreIndentity()
  const options = useMemo(
    () =>
      ({
        variables: {
          url: basePath,
        },
        context: {
          headers: {
            'dw-session-id': sessionID,
          },
        },
        // fetchPolicy: 'network-only', check the todo comment
      } as const),
    [sessionID, basePath]
  )

  const queryResult = useQuery<FrameAdvisorFsaToken>(FRAME_ADVISOR_FSA_TOKEN, options)
  const { refetch } = queryResult

  // TODO: this is a workaround due to bugs in apollo client with network only requests
  // we must manualy refetch each time the component mounts
  useEffect(() => {
    refetch()
  }, [refetch])
  return queryResult
}

export const useFsaUpdateProfileProperty = (userId: string) => {
  const sessionID = useSelector(s => s.session.token)
  const captureComponentAccessToken = useSelector(
    s => s.frameAdvisor.surveyAccessToken || s.frameAdvisor.faceScanAccessToken
  )
  const captureComponentRefreshToken = useSelector(
    s => s.frameAdvisor.surveyRefreshToken || s.frameAdvisor.faceScanRefreshToken
  )

  return useMutation<FsaUpdateProfilePropertyData>(FSA_UPDATE_PROFILE_PROPERTY, {
    variables: {
      userId,
      captureComponentTokens: {
        captureComponentAccessToken,
        captureComponentRefreshToken,
      },
    },
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
  })
}

export const useSizeAdvisorData = (hingeDistance?: number) => {
  const sessionID = useSelector(s => s.session.token)

  return useQuery<SizeAdvisorResultData>(SIZE_ADVISOR, {
    variables: {
      hingeDistance,
    },
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
    skip: !hingeDistance,
  })
}

export const useFaColorPalettes = () => {
  const sessionID = useSelector(s => s.session.token)

  return useQuery<ColorPalettesResultData>(FRAME_ADVISOR_COLOR_PALETTES, {
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
  })
}

export const useFrameAdvisorFsaConsent = () => {
  const sessionID = useSelector(s => s.session.token)

  return useMutation(FRAME_ADVISOR_FSA_CONSENT, {
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
  })
}

export const useFrameAdvisorFsaEmail = () => {
  const sessionID = useSelector(s => s.session.token)

  return useMutation(FRAME_ADVISOR_FSA_EMAIL, {
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
  })
}

export const useFrameAdvisorSuggestion = <T>(data: T, tags?: Record<string, Maybe<string>>) => {
  const sessionID = useSelector(s => s.session.token)
  const { basePath } = useStoreIndentity()

  return useQuery<FrameAdvisorSuggestionMutationResultData>(FRAME_ADVISOR_SUGGESTION_QUERY, {
    variables: {
      url: basePath,
      suggestions: data,
      tags,
    },
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useFAUndertones = () => {
  const sessionID = useSelector(s => s.session.token)
  const { basePath } = useStoreIndentity()

  return useQuery<UndertonesResultData>(FRAME_ADVISOR_UNDERTONES_QUERY, {
    variables: {
      url: basePath,
    },
    context: {
      headers: {
        'dw-session-id': sessionID,
      },
    },
  })
}

interface SessionHandoffToken {
  sessionHandoffToken: {
    token: string
    postURL: string
  }
}
interface SessionHandoffStateSaver {
  sessionHandoffStateSaver: {
    qrcodeUrl: string
  }
}

interface SessionHandoffState {
  sessionHandoffState: {
    state: string
  }
}

interface SessionHandoffTokenStatusCheck {
  sessionHandoffState: {
    isScanned: string
  }
}

interface GreenShipmentInfoResultData {
  greenShipmentInfo: GreenShipmentInfo
}

interface OrderRecapResultData {
  orderRecap?: {
    webOrderId: string
  }
}

interface NuanceTabContentData {
  nuanceTabContent: NuanceTabContent
}

interface MatchesWithResultData {
  matchesWith: {
    numFound: number
    queryTime: number
    products: Product[]
  }
}

export const useSessionHandoffToken = () => {
  const sessionId = useSelector(s => s.session.token)
  const { basePath } = useStoreIndentity()
  const options = useMemo(
    () =>
      ({
        variables: {
          url: basePath,
        },
        context: {
          headers: {
            'dw-session-id': sessionId,
          },
        },
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      } as const),
    [basePath, sessionId]
  )

  const queryResult = useQuery<SessionHandoffToken>(SESSION_HANDOFF_TOKEN, options)

  return queryResult
}

export const useSessionHandoffStatusChecker = () => {
  const sessionId = useSelector(s => s.session.token)
  const options = useMemo(
    () =>
      ({
        context: {
          headers: {
            'dw-session-id': sessionId,
          },
        },
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      } as const),
    [sessionId]
  )

  return useLazyQuery<SessionHandoffTokenStatusCheck>(SESSION_HANDOFF_TOKEN_STATUS_CHECK, options)
}

export const useSessionHandoffStateSaver = () => {
  return useMutation<SessionHandoffStateSaver>(SESSION_HANDOFF_STATE_SAVER)
}

export const useSessionHandoffStateRetriever = (token: string) => {
  const sessionId = useSelector(s => s.session.token)
  const options = useMemo(
    () =>
      ({
        context: {
          headers: {
            'dw-session-id': sessionId,
          },
        },
        variables: {
          token,
        },
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      } as const),
    [sessionId, token]
  )

  const queryResult = useQuery<SessionHandoffState>(SESSION_HANDOFF_STATE, options)

  return queryResult
}

export const useGreenShipmentInfo = () => {
  const { basePath } = useStoreIndentity()

  return useLazyQuery<GreenShipmentInfoResultData>(GREEN_SHIPMENT_INFO_QUERY, {
    variables: {
      url: basePath,
    },

    fetchPolicy: 'cache-first',
  })
}

export const useOrderRecap = () => {
  const sessionId = useSelector(s => s.session.token)
  const { cartIdentifier, orderList } = useSelector(
    state => state.checkout as CheckoutState,
    shallowEqual
  )

  const orderItemIds = useMemo(() => {
    return orderList.map((o: OrderItem) => o.orderItemIdentifier)
  }, [orderList])

  const options = useMemo(
    () =>
      ({
        variables: {
          cartIdentifier,
          orderItemIdentifiersToCheckout: orderItemIds,
        },
        context: {
          headers: {
            'dw-session-id': sessionId,
          },
        },
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      } as const),
    [cartIdentifier, orderItemIds, sessionId]
  )

  const queryResult = useQuery<OrderRecapResultData>(ORDER_RECAP, options)

  return queryResult
}

interface NuanceTabContentQueryData {
  id?: string
  isNuanceFrame?: boolean
  isNuanceAccessories?: boolean
}

export const useNuanceTabContent = (
  data: NuanceTabContentQueryData,
  options?: QueryFunctionOptions
) => {
  const { basePath } = useStoreIndentity()

  return useQuery<NuanceTabContentData>(NUANCE_TAB_CONTENT, {
    variables: {
      url: basePath,
      ...data,
    },
    fetchPolicy: 'cache-first',
    ...options,
  })
}

export const useCartRecommendedAccessories = (products: Product[]) => {
  return {
    data: {
      cartRecommendedAccessories: {
        numFound: 0,
        products: [],
      },
    },
  }
}
