import { PreselectedFilters } from 'types/filter'

export const TOGGLE_MENU_DRAWER = 'ui/TOGGLE_MENU_DRAWER'
export const TOGGLE_FILTERS_DRAWER = 'ui/TOGGLE_FILTERS_DRAWER'
export const HIDE_ALL_DRAWER = 'ui/HIDE_ALL_DRAWER'
export const TOGGLE_MINICART_DRAWER = 'ui/TOGGLE_MINICART_DRAWER'
export const TOGGLE_SEARCH_DRAWER = 'ui/TOGGLE_SEARCH_DRAWER'
export const TOGGLE_PDP_INFO_DRAWER = 'ui/TOGGLE_PDP_INFO_DRAWER'
export const TOGGLE_LANGUAGE_SELECTOR = 'ui/TOGGLE_LANGUAGE_SELECTOR'
export const SET_HAS_CHANGED_PAGE = 'ui/SET_HAS_CHANGED_PAGE'
export const SET_VISIBILITY_WARNING_MODAL = 'ui/SET_VISIBILITY_WARNING_MODAL'
export const TOGGLE_SIMILAR_STYLE = 'ui/TOGGLE_SIMILAR_STYLE'
export const SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE = 'ui/SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE'
export const UPDATE_PLP_PRESELECTED_FILTERS = 'ui/UPDATE_PLP_PRESELECTED_FILTERS'
export const UPDATE_FA_PRESELECTED_FILTERS = 'ui/UPDATE_FA_PRESELECTED_FILTERS'
export const CLEAR_PLP_PRESELECTED_FILTERS = 'ui/CLEAR_PLP_PRESELECTED_FILTERS'
export const CLEAR_FA_PRESELECTED_FILTERS = 'ui/CLEAR_FA_PRESELECTED_FILTERS'
export const ADD_VISITED_EXCLUSIVE_LENSES_MODEL = 'ui/ADD_VISITED_EXCLUSIVE_LENSES_MODEL'
export const TOGGLE_BARCODE_SCANNER_DRAWER = 'ui/TOGGLE_BARCODE_SCANNER_DRAWER'
export const SET_FILTERS_COUNT = 'ui/SET_FILTERS_COUNT'
export const TOGGLE_FRAME_ADVISOR_MYSELECTION = 'ui/TOGGLE_FRAME_ADVISOR_MYSELECTION'
export const TOGGLE_PROFILE_RECAP_DRAWER = 'ui/TOGGLE_PROFILE_RECAP_DRAWER'
export const SET_VISIBLE_MODAL = 'ui/SET_VISIBLE_MODAL'
export const SET_VISIBLE_DRAWER = 'ui/SET_VISIBLE_DRAWER'
export const SET_VISIBLE_DIGITAL_COUVETTE = 'u/SET_VISIBLE_DIGITAL_COUVETTE'

export const CLOSE_VISIBLE_DRAWER = 'ui/CLOSE_VISIBLE_DRAWER'

export const toggleMenuDrawer: ToggleMenuDrawerAction = {
  type: TOGGLE_MENU_DRAWER,
}

export const toggleMiniCartDrawer: ToggleMiniCartDrawerAction = {
  type: TOGGLE_MINICART_DRAWER,
}
export const toggleFiltersDrawer: ToggleFiltersDrawerAction = {
  type: TOGGLE_FILTERS_DRAWER,
}
export const toggleSearchDrawer: ToggleSearchDrawerAction = {
  type: TOGGLE_SEARCH_DRAWER,
}
export const togglePdpInfosDrawer: TogglePdpInfosDrawerAction = {
  type: TOGGLE_PDP_INFO_DRAWER,
}
export const toggleProfileRecapDrawer: ToggleProfileRecapAction = {
  type: TOGGLE_PROFILE_RECAP_DRAWER,
}
export const toggleLanguageSelectorList: ToggleLanguageSelectorAction = {
  type: TOGGLE_LANGUAGE_SELECTOR,
}
export const toggleScanBarcodeDrawer: ToggleScanBarcodeDrawerAction = {
  type: TOGGLE_BARCODE_SCANNER_DRAWER,
}
export const hideAllDrawer: HideAllDrawerAction = {
  type: HIDE_ALL_DRAWER,
}
export const setHasChangedPage: SetHasChangedPageAction = {
  type: SET_HAS_CHANGED_PAGE,
}

export const toggleSimilarStyleAction: ToggleSimilarStyleAction = {
  type: TOGGLE_SIMILAR_STYLE,
}

export const toggleFrameAdvisorMySelectionAction: ToggleFrameAdvisorMySelectionAction = {
  type: TOGGLE_FRAME_ADVISOR_MYSELECTION,
}

export const setCustomLogoHeroSlideStateAction = (
  isActive: boolean
): CustomLogoHeroSlideActiveAction => ({
  type: SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE,
  payload: {
    isActive,
  },
})

export interface SetVisibilityWarningModal {
  type: typeof SET_VISIBILITY_WARNING_MODAL
  payload: {
    visible: boolean
  }
}

export const setVisibilityWarningModal = (visible: boolean): SetVisibilityWarningModal => ({
  type: SET_VISIBILITY_WARNING_MODAL,
  payload: { visible },
})

export interface CustomLogoHeroSlideActiveAction {
  type: typeof SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE
  payload: {
    isActive: boolean
  }
}
export interface ToggleSimilarStyleAction {
  type: typeof TOGGLE_SIMILAR_STYLE
}
export interface HideAllDrawerAction {
  type: typeof HIDE_ALL_DRAWER
}
export interface SetHasChangedPageAction {
  type: typeof SET_HAS_CHANGED_PAGE
}

export interface ToggleMenuDrawerAction {
  type: typeof TOGGLE_MENU_DRAWER
}
export interface ToggleMiniCartDrawerAction {
  type: typeof TOGGLE_MINICART_DRAWER
}
export interface ToggleFiltersDrawerAction {
  type: typeof TOGGLE_FILTERS_DRAWER
}
export interface ToggleSearchDrawerAction {
  type: typeof TOGGLE_SEARCH_DRAWER
}
export interface TogglePdpInfosDrawerAction {
  type: typeof TOGGLE_PDP_INFO_DRAWER
}
export interface ToggleProfileRecapAction {
  type: typeof TOGGLE_PROFILE_RECAP_DRAWER
}
export interface ToggleLanguageSelectorAction {
  type: typeof TOGGLE_LANGUAGE_SELECTOR
}

export interface ToggleScanBarcodeDrawerAction {
  type: typeof TOGGLE_BARCODE_SCANNER_DRAWER
}

export interface UpdatePLPPreselectedFilters {
  type: typeof UPDATE_PLP_PRESELECTED_FILTERS
  payload: {
    filters: PreselectedFilters
  }
}

export interface UpdateFaPreselectedFilters {
  type: typeof UPDATE_FA_PRESELECTED_FILTERS
  payload: {
    filters: PreselectedFilters
  }
}

export interface ClearPLPPreselectedFilters {
  type: typeof CLEAR_PLP_PRESELECTED_FILTERS
}
export interface ClearFaPreselectedFilters {
  type: typeof CLEAR_FA_PRESELECTED_FILTERS
}

export interface AddVisitedExclusiveLensesModel {
  type: typeof ADD_VISITED_EXCLUSIVE_LENSES_MODEL
  payload: {
    productModel: string
  }
}

export interface SetFiltersCount {
  type: typeof SET_FILTERS_COUNT
  payload: number
}

export interface ToggleFrameAdvisorMySelectionAction {
  type: typeof TOGGLE_FRAME_ADVISOR_MYSELECTION
}

export const updatePLPPreselectedFilters = (
  filters: PreselectedFilters
): UpdatePLPPreselectedFilters => ({
  type: UPDATE_PLP_PRESELECTED_FILTERS,
  payload: {
    filters,
  },
})

export const clearPlpPreselectedFilters = (): ClearPLPPreselectedFilters => ({
  type: CLEAR_PLP_PRESELECTED_FILTERS,
})

export const updateFaPreselectedFilters = (
  filters: PreselectedFilters
): UpdateFaPreselectedFilters => ({
  type: UPDATE_FA_PRESELECTED_FILTERS,
  payload: {
    filters,
  },
})

export const clearFaPreselectedFilters = (): ClearFaPreselectedFilters => ({
  type: CLEAR_FA_PRESELECTED_FILTERS,
})

export const addVisitedExclusiveLensesModel = (productModel): AddVisitedExclusiveLensesModel => ({
  type: ADD_VISITED_EXCLUSIVE_LENSES_MODEL,
  payload: {
    productModel,
  },
})

export const setFiltersCount = (filtersCount: number): SetFiltersCount => {
  return {
    type: SET_FILTERS_COUNT,
    payload: filtersCount,
  }
}

export interface SetVisibleModal {
  type: typeof SET_VISIBLE_MODAL
  payload: {
    modalKey: string
  }
}

export const setVisibleModal = (modalKey: string): SetVisibleModal => ({
  type: SET_VISIBLE_MODAL,
  payload: {
    modalKey,
  },
})

export interface SetVisibleDrawer {
  type: typeof SET_VISIBLE_DRAWER
  payload: {
    drawerKey: string
  }
}

export const setVisibleDrawer = (drawerKey: string): SetVisibleDrawer => ({
  type: SET_VISIBLE_DRAWER,
  payload: {
    drawerKey,
  },
})

export interface SetVisibleDigitalCouvette {
  type: typeof SET_VISIBLE_DIGITAL_COUVETTE
  payload: {
    visibleDigitalCouvette: boolean
  }
}

export const setVisibleDigitalCouvette = (
  visibleDigitalCouvette: boolean
): SetVisibleDigitalCouvette => ({
  type: SET_VISIBLE_DIGITAL_COUVETTE,
  payload: {
    visibleDigitalCouvette,
  },
})

export interface CloseVisibleDrawerAction {
  type: typeof CLOSE_VISIBLE_DRAWER
  payload: {
    drawerKey: string
  }
}

export const closeVisibleDrawer = (drawerKey: string): CloseVisibleDrawerAction => ({
  type: CLOSE_VISIBLE_DRAWER,
  payload: {
    drawerKey,
  },
})

export type UIActionTypes =
  | ToggleMenuDrawerAction
  | ToggleFiltersDrawerAction
  | ToggleMiniCartDrawerAction
  | ToggleSearchDrawerAction
  | ToggleProfileRecapAction
  | ToggleLanguageSelectorAction
  | ToggleFrameAdvisorMySelectionAction
  | TogglePdpInfosDrawerAction
  | HideAllDrawerAction
  | SetHasChangedPageAction
  | SetVisibilityWarningModal
  | ToggleSimilarStyleAction
  | UpdatePLPPreselectedFilters
  | ClearPLPPreselectedFilters
  | UpdateFaPreselectedFilters
  | ClearFaPreselectedFilters
  | CustomLogoHeroSlideActiveAction
  | ToggleScanBarcodeDrawerAction
  | AddVisitedExclusiveLensesModel
  | SetFiltersCount
  | SetVisibleModal
  | SetVisibleDrawer
  | CloseVisibleDrawerAction
  | SetVisibleDigitalCouvette
