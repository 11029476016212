import { Text } from 'components/Typography'
import { TFunction } from 'i18next'
import formatPrice from 'libs/formatPrice'
import { getHighestPrice, getLowestPrice, getRangePrices } from 'libs/price'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductPriceType } from 'types/price'
import { Product } from 'types/product'

export const PRICE_STYLE_RANGE = 'range'
export const PRICE_STYLE_FROM = 'from'
export const PRICE_STYLE_UPTO = 'upto'

interface ProductPriceProps {
  product: Product
  priceStyle?: ProductPriceType
  quantity?: number
  className?: string
}

interface RenderPriceArgs {
  priceStyle?: string
  product: Product
  quantity?: number
  t: TFunction
}

const renderPrice = ({ priceStyle = 'default', product, t }: RenderPriceArgs) => {
  const { price } = product
  const currency = price ? price.currency : null

  switch (priceStyle) {
    case PRICE_STYLE_FROM: {
      return t('ProductView.from', {
        price: getLowestPrice(product),
      })
    }
    case PRICE_STYLE_UPTO: {
      return t('ProductView.upTo', {
        price: getHighestPrice(product),
      })
    }
    case PRICE_STYLE_RANGE: {
      return getRangePrices(product)
    }
    default: {
      return price && currency ? formatPrice(price.current.value, currency) : 0
    }
  }
}
export const ProductPrice: React.FC<ProductPriceProps> = ({ priceStyle, product, className }) => {
  const { t } = useTranslation()

  return (
    <Text data-test="text-price" className={className}>
      {renderPrice({ product, priceStyle, t })}
    </Text>
  )
}
