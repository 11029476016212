import { useStoreContext } from './useStoreContext'

export const useFilteredEyewearBrands = () => {
  const store = useStoreContext()
  const eyeWearBrands = store.brands.filter(brand => {
    const hasSun = store.filteredBrandsBySunCategory.some(e => e.code === brand.code)
    const hasOptical = store.filteredBrandsByOpticalCategory.some(e => e.code === brand.code)
    return hasSun || hasOptical ? brand : false
  })
  return eyeWearBrands
}
