import { removeItemFromCart } from 'actions/cart'
import { hideAllDrawer } from 'actions/ui'
import 'antd/lib/grid/style'
import CartMerchRecommender from 'components/Cart/CartMerchRecommender'
import ConditionalRender from 'components/ConditionalRender'
import { PanelHeader } from 'components/core/PanelHeader'
import { PanelHeaderTitle, PanelHeaderTitleCount } from 'components/core/PanelHeader/styles'
import { Drawer } from 'components/Drawer'
import { DrawerPanelContainer } from 'components/pdp/styles'
import config from 'config'
import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useAction, useActions } from 'hooks/useActions'
import { useCart } from 'hooks/useCart'
import { useRTRVisibility } from 'hooks/useRTRVisibility'
import { useStoreContext } from 'hooks/useStoreContext'
import { analyticsFormatCartItems, analyticsFormatProduct, EVENTS_ID } from 'libs/analytics'
import { shouldShowPrice } from 'libs/cart'
import formatPrice from 'libs/formatPrice'
import { isNuanceProduct } from 'libs/nuance'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CustomProduct } from 'types/product'
import { PrimaryButton } from '../core/Button'
import MiniCartItem from './MiniCartItem'
import {
  DrawerMiniCartItemsContainer,
  MiniCartContainer,
  MiniCartEmpty,
  MiniCartItemsContainer,
  MiniCartPanelHeader,
  MiniCartSummuryContainer,
  StyledLink,
  TotalText,
  TotalValue,
} from './styles'

export const MiniCartDrawer: React.FC = () => {
  const { t } = useTranslation()
  const store = useStoreContext()
  const cartItems = useSelector(state => state.cart.items)

  const { isRTREnabled } = useRTRVisibility()

  const { currency, subtot } = useCart()

  const visible = useSelector(({ ui }) => {
    return ui.minicartDrawerIsVisible
  })

  const actions = useActions({
    removeItemFromCart,
  })

  const closeDrawer = useAction(hideAllDrawer)

  const { numberItems, items } = useCart()

  const sendAnalyticsEvent = useSendAnalyticsEvent(
    {
      id: EVENTS_ID.impression,
      Page_Section2: 'MiniCart',
      Products: analyticsFormatCartItems(items, true),
      Vm_IsBrowserSupported: store?.vmEnabled ? '1' : '0',
    },
    true,
    true
  )

  useEffect(() => {
    if (visible) sendAnalyticsEvent()
  }, [visible, sendAnalyticsEvent])

  const sendRemoveItemFromCart = useSendAnalyticsEvent((product: CustomProduct) => ({
    id: 'Prods-Delete',
    Products: {
      // there is no cart ID or quantity information in CustomProduct type
      [product.UPC]: analyticsFormatProduct({ item: product }),
    },
  }))

  const handleClickRemoveFromCart = useCallback(
    id => {
      const itemToRemove = items.find(item => item.id === id)
      const product = itemToRemove?.product

      actions.removeItemFromCart(id)
      if (product) {
        sendRemoveItemFromCart(product)
      }
    },
    [actions, items, sendRemoveItemFromCart]
  )

  const itemWithoutPrice = items.some(item => item.product.price.current.value === 0)

  const price = <TotalValue>{formatPrice(subtot, currency)}</TotalValue>

  const nuanceItems = useMemo(
    () => cartItems?.filter(item => isNuanceProduct(item.product)) ?? [],
    [cartItems]
  )

  return (
    <Drawer position="left" visible={visible} onOverlayClick={closeDrawer}>
      <DrawerPanelContainer>
        <MiniCartContainer>
          <MiniCartPanelHeader>
            <PanelHeader onClose={closeDrawer}>
              <PanelHeaderTitle>
                {t('SiderMenu.title')}
                <PanelHeaderTitleCount>({numberItems})</PanelHeaderTitleCount>
              </PanelHeaderTitle>
            </PanelHeader>

            {numberItems > 0 && (
              <StyledLink to={`${store.url}/cart`}>
                <PrimaryButton onClick={closeDrawer}>{t('SummaryCart.go')}</PrimaryButton>
              </StyledLink>
            )}
          </MiniCartPanelHeader>

          {!itemWithoutPrice && shouldShowPrice(items, store.srpCustom, store.srpStandard) && (
            <MiniCartSummuryContainer>
              <TotalText>{t('SummaryCart.total')}</TotalText>
              {price}
            </MiniCartSummuryContainer>
          )}

          {numberItems === 0 && <MiniCartEmpty>{t('SiderMenu.empty')}</MiniCartEmpty>}

          {numberItems > 0 && (
            <DrawerMiniCartItemsContainer>
              <MiniCartItemsContainer>
                {items.map((cartItem, i) => {
                  return (
                    <MiniCartItem
                      cartItem={cartItem}
                      key={i}
                      removeItemFromCart={handleClickRemoveFromCart}
                      showPrice={cartItem.product.customItem ? store.srpCustom : store.srpStandard}
                      showRTRIcon={isRTREnabled}
                    />
                  )
                })}
              </MiniCartItemsContainer>
            </DrawerMiniCartItemsContainer>
          )}
          <ConditionalRender
            condition={
              config.newMerchAccessoriesBag &&
              config.newMerchAccessoriesBag.toLowerCase() === 'true' &&
              nuanceItems.length > 0
            }
          >
            <CartMerchRecommender cartItems={nuanceItems} />
          </ConditionalRender>
        </MiniCartContainer>
      </DrawerPanelContainer>
    </Drawer>
  )
}
