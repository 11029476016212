import {
  CHANGE_FORM_PANEL,
  CheckoutActionTypes,
  DELETE_ORDER_ITEM,
  RESET_CHECKOUT,
  SET_CART_INFO,
  SET_CHECKOUT_TYPE,
  SET_CS_ORDER_NOTE,
  SET_CUSTOMER_ADDRESS,
  SET_DATA,
  SET_FORM_ERRORS,
  SET_MODAL,
  SET_PURCHASE_NUMBER,
  SET_WEB_ORDER_IDENTIFIER,
  UPDATE_ORDER_ITEM,
  VALIDATE_FIELD,
} from 'actions/checkout'
import { validateField } from 'libs/checkoutV3'
import {
  CartType,
  CheckoutFormData,
  CheckoutModal,
  CheckoutV3Type,
  FormErrors,
  FormPanel,
  OrderItem,
  OrderItemShipToConsumer,
} from 'types/checkoutV3'

export interface CheckoutState {
  modal: CheckoutModal
  type: CheckoutV3Type
  cartIdentifier: string
  orderList: OrderItem[] | OrderItemShipToConsumer[] | null
  customerAddress: string
  purchaseNumber: string
  orderNote: string
  webOrderIdentifier: string | null
  data: CheckoutFormData
  errors: FormErrors
  activePanels?: FormPanel[]
  cartType: CartType
}

const initialState: CheckoutState = {
  modal: {
    type: '',
    visible: false,
  },
  type: 'Standard',
  cartIdentifier: '',
  orderList: null,
  customerAddress: null,
  purchaseNumber: '',
  orderNote: '',
  webOrderIdentifier: null,
  data: {
    shipping: 'store',
    shippingType: 'G', // TODO: handle the default cases
    localName: '',
    localName2: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerification: '',
    phone: '',
    address: '',
    address1: '',
    zip: '',
    city: '',
    state: undefined,
    marketing: '',
    profiling: '',
    storeAddressId: '',
  },
  errors: {},
  cartType: 'frame-only',
}

export default (state = initialState, action: CheckoutActionTypes) => {
  switch (action.type) {
    case SET_MODAL: {
      const {
        payload: { visible, type },
      } = action
      return { ...state, modal: { visible, type } }
    }

    case SET_CART_INFO: {
      return {
        ...state,
        cartIdentifier: action.payload.cartIdentifier,
        orderList: action.payload.orderItems,
        customerAddress: action.payload.customerAddress.addressIdentifier,
      }
    }

    case SET_CUSTOMER_ADDRESS: {
      return {
        ...state,
        customerAddress: action.payload,
      }
    }

    case SET_PURCHASE_NUMBER: {
      return {
        ...state,
        purchaseNumber: action.payload,
      }
    }

    case SET_CS_ORDER_NOTE: {
      return {
        ...state,
        orderNote: action.payload,
      }
    }

    case SET_WEB_ORDER_IDENTIFIER: {
      return {
        ...state,
        webOrderIdentifier: action.payload,
      }
    }

    case UPDATE_ORDER_ITEM: {
      const { payload } = action
      const index = state.orderList.findIndex(
        e => e.orderItemIdentifier === payload.orderItemIdentifier
      )
      const newState = { ...state, orderList: [...state.orderList] }

      newState.orderList[index] = payload
      return newState
    }

    case DELETE_ORDER_ITEM: {
      const { payload } = action
      const filteredList = state.orderList.filter(
        e => e.orderItemIdentifier !== payload.orderItemIdentifier
      )
      return { ...state, orderList: filteredList }
    }

    case CHANGE_FORM_PANEL: {
      const { activePanels } = action.payload
      return {
        ...state,
        data: { ...state.data },
        errors: { ...state.errors },
        activePanels,
        cartType: state.cartType,
      }
    }
    case SET_DATA: {
      const { field, data } = action.payload
      const newdata = { ...state.data }
      newdata[field] = data
      return {
        ...state,
        activePanels: state.activePanels,
        errors: { ...state.errors },
        data: newdata,
        cartType: state.cartType,
      }
    }

    case SET_CHECKOUT_TYPE: {
      return { ...state, type: action.payload }
    }

    case VALIDATE_FIELD: {
      const { field, value, storeData, validators } = action.payload
      // need to check here for actual form data
      const error = validateField(validators, value, state.data, storeData)
      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: error,
        },
      }
    }

    case SET_FORM_ERRORS: {
      const { errors } = action.payload
      return {
        ...state,
        errors: { ...errors },
      }
    }

    case RESET_CHECKOUT: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
