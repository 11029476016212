import { formatProductPrice } from 'libs/formatPrice'
import React from 'react'

type FormattedPriceProps = {
  price: number
  fractionDigits?: number
  currency: string
}

export const Price: React.FC<FormattedPriceProps> = ({ price, currency, fractionDigits }) => {
  return <>{formatProductPrice(price, currency, fractionDigits)}</>
}
