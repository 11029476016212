import { Form, Input } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'
import jwt from 'jsonwebtoken'
import { cleanLocalStorageData } from 'libs/auth'
import { authenticate } from 'libs/routing'
import qs from 'qs'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { PrimaryButton } from '../components/core/Button'
import { ReplyParsedToken } from './LoginRedirect'

const FormItem = Form.Item
const { TextArea } = Input

const LoginBypassWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 9.09rem;
`

const FormWrapper = styled.div`
  width: 70%;
`

const handleSubmit = (e, form, readOnly) => {
  e.preventDefault()
  form.validateFields((err, values) => {
    if (!err) {
      cleanLocalStorageData()
      const parsedToken: ReplyParsedToken = JSON.parse(atob(values.token.trim().split('.')[1]))
      authenticate(values.token.trim(), `${parsedToken.isMigrated || false}`, readOnly || false)
    }
  })
}

interface LoginBypass extends FormComponentProps, RouteComponentProps {}

const LoginBypass: React.FC<LoginBypass> = props => {
  const { form } = props
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const token = params['token']
  const readonly = params['read-only']

  if (token) {
    cleanLocalStorageData()
    const parsedToken: ReplyParsedToken = jwt.decode(token)
    authenticate(token.trim(), `${parsedToken.isMigrated || false}`, readonly)
  }

  const { getFieldDecorator, getFieldError, isFieldTouched } = form

  const tokenError = isFieldTouched('deviceid') && getFieldError('deviceid')

  const hasErrors = () => {
    const fieldsError = form.getFieldsError()
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  return (
    <LoginBypassWrapper>
      <FormWrapper>
        <Form layout="vertical" onSubmit={e => handleSubmit(e, form, readonly)}>
          <>
            <FormItem label="Token" validateStatus={tokenError ? 'error' : ''} help={''}>
              {getFieldDecorator('token', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                  },
                ],
              })(<TextArea rows={4} />)}
            </FormItem>
            <PrimaryButton type="submit" disabled={hasErrors()}>
              Send
            </PrimaryButton>
          </>
        </Form>
      </FormWrapper>
    </LoginBypassWrapper>
  )
}

const LoginBypassForm = Form.create()(LoginBypass)

export default LoginBypassForm
