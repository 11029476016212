import { OOCustomRecipe, RBCustomRecipe } from 'types/customRecipe'
import * as graphqlTypes from './graphqlTypes'

export type Product = graphqlTypes.Product & {
  colorsAvailable?: graphqlTypes.Scalars['Int']
}

export type ProductModel = graphqlTypes.ProductModel

export interface RaybanPLPItem {
  model: {
    name: string
    upc: string
    image: string
  }
  configId: string
  category: string
}

export interface OakleyPLPItem {
  name: string
  category: string
  vendorId: string
  fluidId: string
  productId: number
  variant: string
  recipeId: number
  wf: string
}

export interface CustomOOProduct {
  jawColorLabel: string
  templeLongColorLabel: string
  stemsColorLabel: string
  iconColorLabel: string
  clipsColorLabel: string
  frameFrontColorLabel: string
  templeTipsColorLabel: string
  lensColor2?: string
  iconColor2?: string
}

export interface CustomitemEngraving {
  color: string
  font: string
  sides: CustomSide[]
  caseType?: string
  caseEngraving?: string
  caseColor?: string
  caseFont?: string
  templetipColor?: string
  frameEngravingType?: string
  logo?: string
}

export interface CustomSide {
  title: string
  value: string
}

export interface CustomProduct extends Product, Partial<CustomOOProduct> {
  customItem?: OOCustomRecipe | RBCustomRecipe
  customitemEngraving?: CustomitemEngraving
  recipeId?: string
  type?: string
  image?: string
}

export type Size = {
  size: graphqlTypes.Maybe<number>
  id: string
  UPC: string
  sizeFull: graphqlTypes.Maybe<string>
}

export interface DataAttrs {
  description?: string
  link?: string
  wishlist?: string
  removeBtn?: string
  id?: string
  title?: string
}

export interface PDPProduct extends Product {
  tryLenses?: graphqlTypes.TryLenses
}

type StellaProduct = Product
type SuperNovaProduct = Product
export type ElectronicsProduct = StellaProduct | SuperNovaProduct

export enum Collection {
  // modified on BFF side
  Optical = '1',
  Sun = '2',
  Stella = 'E',
  Supernova = 'K',
  // values from api
  ClipOn = '3',
  ReadersGlasses = '4',
  Helmet = '6',
  Accessories = '8',
  AFAApparel = 'A',
  AFAAccessories = 'C',
  MXGoggles = 'G',
  SnowGoggles = 'I',
  AFAFootwear = 'F',
  SnowGogglesAccessories = 'J',
  MXGogglesAccessories = 'H',
  ElectronicsOptical = 'K',
  DisposableSurgicalMask = 'M',
  ElectronicSnowGoggles = 'N',
  Miscellaneous = 'Y',
}
