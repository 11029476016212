import config from 'config'
import Maybe from 'graphql/tsutils/Maybe'
import { Location as LocationHistory } from 'history'
import { capitalize } from 'lodash'
import qs from 'qs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductTypeValues } from 'types/filter'
import { Orientation } from 'types/utils'
import { checkUserMigrated } from './auth'
import { getLangFromUrl } from './url'

const TOWER_WIDTH = 1080
const TOWER_HEIGHT = 1920

const isTowerDevice = () =>
  window.screen.width === TOWER_WIDTH && window.screen.height === TOWER_HEIGHT

/**
 * Returns true if device is 'ipad'.
 * @param {boolean} countWidth Defines whether count screens smaller that FullHD as iPad.
 * @returns {boolean} Whether device is iPad or not.
 */

export const isIPadView = (): boolean => {
  const UA = window.navigator.userAgent.toLocaleLowerCase()
  // ipad is found where the option request desktop site is disabled
  // macintosh is found by default because request desktop site is enabled
  return UA.indexOf('ipad') !== -1 || UA.indexOf('macintosh') !== -1
}

/**
 * Returns true if device is 'tablet' (resolution < 850).
 * @returns {boolean} Whether device is Tablet or not.
 */
export const isTabletView = () => window.screen.width <= 850

const LANDSCAPE = [-90, 90]
export const isTabletDevice = () =>
  isIPadView() || isTabletView() || LANDSCAPE.includes(window.orientation as number)

// XXX: https://abstractsrl.atlassian.net/browse/M4C-2515 temporary hidden
export const isBarcodeAvailiable = () => checkUserMigrated()

const MOBILE_DEVICES = ['Android', 'iPhone', 'iPad']

/** Trivial check of browser userAgent
 * to guess whether is a mobile device or not
 */
export const isMobileDevice = () => {
  const UA = window.navigator.userAgent
  let isMobile = false
  MOBILE_DEVICES.forEach(el => {
    if (UA.indexOf(el) !== -1) {
      isMobile = true
    }
  })
  return isMobile
}

export const isSamsungSmartTv = () => {
  const samsungUARegex = /Tizen/gm
  const UA = window.navigator.userAgent
  return UA.match(samsungUARegex) ? true : false
}

/** Check whether the vKeyboard should be used or not
 * Vkeyboard is available only on desktop devices
 * and can be enable/disable by a configuration
 */
export const useVKeyboard = () => {
  if (!isTowerDevice() && (isMobileDevice() || isSamsungSmartTv() || isTabletDevice())) {
    return false
  }
  return config.useVKeyboard
}

/** It returns a valid keyboard layout string
 * based on the language in the URL
 */

export const getKeyboardLayout = () => {
  const lang = getLangFromUrl()
  if (lang) {
    const langChuncks = lang.split('-')
    const langLayoutCode = langChuncks.length && langChuncks[1]
    return langLayoutCode && langLayoutCode.toLowerCase()
  }
}

/**
 * Returns a random string Id sufficiently unique
 * @returns {string} unique id
 */
export const uniqueId = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36)

export const capitalizeString = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`

/**
 * Delete dublicated items from array by 'comp'.
 * @param {array} arr initial array of objects.
 * @param {string} comp comporator.
 * @returns {array} array without dublicated items.
 */
export const filterUniqueBy = (arr, comp) =>
  arr
    .map(e => e[comp])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter(e => arr[e])
    .map(e => arr[e])

export const cleanupOneConfiguratorRecipes = () => {
  // rayRecipe and oakRecipe
  Object.keys(localStorage)
    .filter(k => k.startsWith('rayRecipe') || k.startsWith('oakRecipe'))
    .map(k => {
      localStorage.removeItem(k)
    })
}

// TODO: move to common lib
export const convertToCamelCaseAndStripSpecials = (str: string, firstUpperCase = false) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
      return index === 0 && !firstUpperCase ? match.toLowerCase() : match.toUpperCase()
    })
    .replace(/[^0-9a-zA-Z]+/, '')
}

export const convertFromHexToRgb = (colorHex: string) => {
  return colorHex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
}

/**
 * Returns opposite product type value (sun or optical)
 * @param productType
 * @returns {string}
 */
export const getOppositeProductType = (productType: string): string => {
  if (productType === ProductTypeValues.OPTICAL) return ProductTypeValues.SUN
  return ProductTypeValues.OPTICAL
}

export const isLandscapeView = (width: number, height: number): boolean => width > height

export const getWithFallback = (value: Maybe<string>, fallback: string) => {
  if (!value || value === 'undefined') return fallback
  return value
}

export const createMouseEvents = (mouseEventTitles: string[]) =>
  mouseEventTitles.map(
    eventTitle =>
      new MouseEvent(eventTitle, {
        view: window,
        bubbles: true,
        cancelable: true,
      })
  )

export const simulateEvents = (el: Element, events: Event[]) => {
  events.forEach(event => el.dispatchEvent(event))
}

export const getSearchValueFromQs = (location: LocationHistory): string => {
  const { search } = location
  if (search && (search.includes('?q=') || search.includes('?query='))) {
    const parsedParamsQ = qs.parse(search, { ignoreQueryPrefix: true }).q as string
    const parsedParamsQuery = qs.parse(search, { ignoreQueryPrefix: true }).query as string
    const parsedQuery = parsedParamsQ || parsedParamsQuery
    return parsedQuery.replace(/\+/g, ' ')
  }
  return ''
}

/**
 * Returns string with each word starts the first character to upper case
 * * @param {string}
 * * @returns {string}
 */
export const startCase = (string: string): string => string.replace(/\w+/g, capitalize)

/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
export function executeOnce<Args extends unknown[], Return>(
  cb: (...args: Args) => Return,
  id: string
) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const isScreenSaverRunning = (): boolean => {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true }).reset === '1'
}

export const getProductBrandName = (brandCode = '', t): string => {
  return t(`Brand.${brandCode.toUpperCase()}`)
}

export const useGetProductBrandName = () => {
  const { t } = useTranslation()
  return useCallback(
    (brandCode = '') => {
      return getProductBrandName(brandCode, t)
    },
    [t]
  )
}

export const getOrientation = (landscapeNavigation: boolean): Orientation =>
  landscapeNavigation ? Orientation.Landscape : Orientation.Portrait

export const toArray = <T>(arrayOrValue: T | T[] | undefined) => {
  return arrayOrValue ? ([] as T[]).concat(arrayOrValue) : []
}

export const removeSpaces = (string: string) => string.replace(/ /g, '')

export const valueToTuple = <T>(value: T) => [value, value]

export const dataDescriptionForProduct = (product: {
  brand: {
    code: string
  }
  name: string
  UPC: string
}) => `${product.brand.code}_${product.name}_${product.UPC}`

export const isEmailValid = (email: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}
