import config from 'config'
import { sortBy, sum, uniqBy } from 'lodash'
import { BrandFilterOption, FilterOption } from 'types/filter'
import { Brand } from 'types/graphqlTypes'
import { Product } from 'types/product'
import { useGetProductBrandName } from './utils'

const SUB_BRANDS_WITH_PERSONAL_LOGO = ['rw']

export const getSubBrandsStringValues = (brand: Brand, separator = ','): string => {
  return brand.brands
    ? [brand, ...brand.brands].map(el => el.code.toUpperCase()).join(separator)
    : brand.code.toUpperCase() // to set main brend first for chips and title display
}

export const getCumulativeCountNumber = (
  brand: Brand,
  filtersObject: FilterOption[] | BrandFilterOption[]
): number => {
  const brandsStrings = brand.brands.map(el => el.code.toUpperCase())
  const brandsItemCount = filtersObject
    .filter(item => brandsStrings.includes(item.value))
    .map(c => c.count)
  return sum(brandsItemCount)
}

export const getBrandLogoAssetsUrl = (brandName: string): string =>
  `${config.assets.URL}/brands/${brandName}.svg`

export const getProductLogo = (product: Product): string => {
  const originalBrand = product.originalBrand.toLowerCase()
  if (SUB_BRANDS_WITH_PERSONAL_LOGO.includes(originalBrand)) {
    return getBrandLogoAssetsUrl(originalBrand)
  }
  return product.brand.logo.src
}

export const useGetSortedBrands = (brands: Brand[]): Brand[] => {
  const getProductBrandName = useGetProductBrandName()
  const sortedBrands = uniqBy(
    sortBy(
      brands.map(b => ({
        ...b,
        translatedLabel: getProductBrandName(b.code) || b.code,
      })),
      'translatedLabel'
    ),
    'code'
  )
  return sortedBrands
}
