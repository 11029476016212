import currencyFormatter from 'currency-formatter'

const currenciesWithoutPercision = ['JPY', 'HUF']

/**
 * Format price function.
 */
const formatPrice = (price: number, currency: string, precision = 2): string => {
  return currencyFormatter.format(price, {
    code: currency,
    precision: currenciesWithoutPercision.includes(currency) ? 0 : precision,
  })
}

export default formatPrice

/**
 * Gets a currency code and return its symbol
 */
export const getCurrencySymbol = (currencyCode: string): string => {
  const currency = currencyFormatter.findCurrency(currencyCode)
  return currency && currency.symbol
}

const currenciesWithoutPrecision = ['JPY', 'HUF']
const currenciesCommaThousandSeparator = ['JPY']
const currenciesCommaDecimalSeparator = ['AED']
const currencySymbols: Record<string, string> = {
  SGD: 'S$',
  AED: 'DH',
}

export const formatProductPrice = (price: number, currency: string, fractionDigits = 2): string =>
  currencyFormatter.format(price, {
    code: currency,
    precision: currenciesWithoutPrecision.includes(currency) ? 0 : fractionDigits,
    thousand: currenciesCommaThousandSeparator.includes(currency) ? ',' : '.',
    decimal: currenciesCommaDecimalSeparator.includes(currency) ? ',' : undefined,
    symbol: currencySymbols[currency],
  })
