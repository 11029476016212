import { Button, Checkbox, Form, Input } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import config from 'config'
import { getConfigOverrideSessionKey, setConfigSession } from 'libs/configOverride'
import { cloneDeep, get, set } from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { defaultConfig, localStorageKeyPrefix } from '../../config/config.base'

const parseValToNumber = (value: string): number => {
  const parsedValue = Number(value)
  return isNaN(parsedValue) || parsedValue < 0 ? 0 : parsedValue
}

const composeConfigPath = (parentPath: string, currentPath: string) =>
  [parentPath, currentPath].filter(Boolean).join('.')

const OverrideForm = styled(Form)`
  height: 100vh;
  overflow: scroll;

  &.ant-form {
    padding: 2em;

    .ant-form-item-label {
      font-weight: bold;
    }
  }
`
export type ConfigItemValue = string | boolean | number | string[] | undefined

export const ConfigOverride = () => {
  const configOverrideSessionKey = getConfigOverrideSessionKey(localStorageKeyPrefix)
  const processInfo = process.env
  const [configState, setConfigState] = useState(config)
  const [downloadLink, setDownloadLink] = useState('')

  const generateEnvTextFile = () => {
    let output = ''
    Object.keys(processInfo).map((key: string) => {
      let configVal = get(processInfo, key)
      let configRow = configVal !== undefined ? `${key}=${configVal}\n` : ''
      output += configRow
    })

    const data = new Blob([output], {
      type: 'text/plain;charset=utf-8',
    })
    setDownloadLink(window.URL.createObjectURL(data))
  }

  const transferSession = () => {
    const stateKey = `${localStorageKeyPrefix}-state`
    const authKey = `${localStorageKeyPrefix}-auth`
    const urlKey = `${localStorageKeyPrefix}-url`

    const obj = {
      'm4c-state': localStorage[stateKey],
      'm4c-auth': localStorage[authKey],
      'm4c-url': localStorage[urlKey],
    }

    // const keys = Object.keys(obj)
    // const values = Object.values(obj)
    // keys.map((k, i) => localStorage.setItem(`${k}`, values[i]))

    navigator.clipboard.writeText(
      'const obj = ' +
        JSON.stringify(obj) +
        ';const keys = Object.keys(obj);const values = Object.values(obj);keys.map((k, i) => localStorage.setItem(k, values[i]));'
    )
  }

  const saveConfiguration = (configPath: string, updatedValue: ConfigItemValue) => {
    const updatedConfig = cloneDeep(configState)
    set(updatedConfig, configPath, updatedValue)

    setConfigSession(configOverrideSessionKey, updatedConfig)
    setConfigState(updatedConfig)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    configPath: string,
    inputType?: string
  ): void => {
    const { value } = e.target
    const updatedValue = inputType && inputType === 'number' ? parseValToNumber(value) : value
    saveConfiguration(configPath, updatedValue)
  }

  const handleCheckBoxChange = (e: CheckboxChangeEvent, configPath: string): void => {
    const { name, checked } = e.target
    if (name) {
      saveConfiguration(configPath, checked)
    }
  }

  const resetConfigurationToDefault = () => {
    setConfigState(defaultConfig)
    sessionStorage.removeItem(configOverrideSessionKey)
  }

  const renderInputText = (
    key: string,
    value: string | number,
    configPath: string,
    type: string
  ) => (
    <Form.Item label={key} key={key}>
      <Input
        type={type}
        name={key}
        onChange={e => handleInputChange(e, configPath, type)}
        value={value}
      />
    </Form.Item>
  )

  const renderInputCheckbox = (key: string, value: boolean, configPath: string) => (
    <Form.Item label={key} key={key}>
      <Checkbox
        checked={value}
        name={key}
        onChange={e => {
          handleCheckBoxChange(e, configPath)
        }}
      />
    </Form.Item>
  )

  const renderAllFormItems = (configObject: Record<string, unknown>, configParentField = '') => {
    return Object.entries(configObject).map(([configKey, configVal]) => {
      const renderFormItem = (
        renderingFunc: (
          configKey: string,
          configVal: string | number | boolean,
          configParentField: string,
          type: string
        ) => JSX.Element,
        type = ''
      ) =>
        renderingFunc(
          configKey,
          configVal as string | number | boolean,
          composeConfigPath(configParentField, configKey),
          type
        )

      switch (typeof configVal) {
        case 'string': {
          return renderFormItem(renderInputText, 'text')
        }
        case 'number': {
          return renderFormItem(renderInputText, 'number')
        }
        case 'boolean': {
          return renderFormItem(renderInputCheckbox)
        }
        case 'object': {
          configVal = Array.isArray(configVal) ? { [configKey]: configVal.toString() } : configVal
          return renderAllFormItems(
            configVal as Record<string, unknown>,
            composeConfigPath(configParentField, configKey)
          )
        }
      }
    })
  }

  return (
    <OverrideForm name="conf-form" layout="vertical">
      <Button onClick={() => transferSession()}>Copy session to clipboard</Button>
      <Button download="env.remoteConfig" href={downloadLink} onClick={generateEnvTextFile}>
        Download this configuration
      </Button>
      <Button onClick={resetConfigurationToDefault} htmlType="reset">
        reset
      </Button>
      {renderAllFormItems(configState)}
      <Form.Item></Form.Item>
    </OverrideForm>
  )
}
